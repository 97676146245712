import React from 'react';
import {Button, Stack} from '@mui/material';
import {useListContext} from 'components/list/ListContext';

const EntriesFilters = () => {
  const {addFilters} = useListContext();

  const handleFilterClick = (status: string) => {
    addFilters({status});
  };

  return (
    <Stack direction="row" alignItems="left" justifyContent="flex-start" gap={2}>
      <Button onClick={() => handleFilterClick('all')}>All</Button>
      <Button onClick={() => handleFilterClick('accept')}>Accept</Button>
      <Button onClick={() => handleFilterClick('reject')}>Reject</Button>
      <Button onClick={() => handleFilterClick('waiting')}>Waiting</Button>
    </Stack>
  );
};

export default EntriesFilters;
