import React from 'react';

import {ResourceContextProvider} from 'components/record/ResourceContext';
import {Person} from '@mui/icons-material';
import {ListField} from 'components/list/Fields';
import List from 'components/list/List';

const AllUsers = () => {
  return (
    <ResourceContextProvider resource="User">
      <List hidePagination title="All Users" avatar={<Person />}>
        <ListField source="name" label="Name" />
        <ListField source="role.name" label="Role" />
      </List>
    </ResourceContextProvider>
  );
};

export default AllUsers;
