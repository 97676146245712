import React from 'react';
import {useController} from 'react-hook-form';
import FilePicker from 'components/common/FilePicker';
import {toast} from 'react-toastify';
import {FormControl} from '@mui/material';

const ImageFileUpload = ({onImageSrcChange}: {onImageSrcChange: (data: string) => void}) => {
  const {
    field: {onChange: onChangeFile}
  } = useController({name: 'file'});

  const {
    field: {onChange: onChangeHeight}
  } = useController({name: 'height'});

  const {
    field: {onChange: onChangeWidth}
  } = useController({name: 'width'});

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const fileTypes = ['jpg', 'jpeg'];
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0] as File;
      const fileName = file.name || '';
      const extension = fileName.split('.').pop()?.toLowerCase() || ' ';
      if (fileTypes.indexOf(extension) > -1) {
        const reader = new window.FileReader();
        reader.onload = (f) => {
          const image = new window.Image();
          image.onload = function () {
            onChangeFile(file);
            onChangeWidth(image.width);
            onChangeHeight(image.height);
            onImageSrcChange(image.src);
          };
          image.onerror = (error) => {
            console.log(error);
            // formContext.setError(name, {message: error as string});
          };
          image.src = f.target?.result as string;
        };
        reader.readAsDataURL(file);
      } else {
        toast.error('Wrong file type');
        // formContext.setError(name, {message: 'wrong file extension'});
      }
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      <FilePicker label="Upload Map Image" accept=".jpg, .jpeg" onFileChange={handleChange} />;
    </FormControl>
  );
};

export default ImageFileUpload;
