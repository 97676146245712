import {LeafletEventHandlerFnMap} from 'leaflet';
import {useMapEvents} from 'react-leaflet';

const MapEventHandlers = (props: LeafletEventHandlerFnMap) => {
  const {click = () => {}, zoom = () => {}} = props;
  useMapEvents({
    click,
    zoom
  });
  return null;
};

export default MapEventHandlers;
