import LocalStorageService from './LocalStorageService'

const isAuthenticated = () => {
  const role = LocalStorageService.getUserRole()
  return (role === 'admin' || role === 'moderator')
}

const isAdmin = () => {
  return LocalStorageService.getUserRole() === 'admin'
}

export default {
  isAdmin,
  isAuthenticated
}
