import React from 'react';
import {Button} from '@mui/material';
import {useDialogContext} from 'components/dialog/DialogContext';
import {useResourceContext} from 'components/record/ResourceContext';
import {useMutate} from 'hooks/useFetchData';
import {toast} from 'react-toastify';
import {useRecordContext} from 'components/record/useRecordContext';
import {RecordType} from 'components/record/RecordContext';
import {useListContext} from 'components/list/ListContext';

const DeleteConfirmButton = ({onSuccess, many = false}: {onSuccess?: () => void; many?: boolean}) => {
  const {resource, params} = useResourceContext();
  const {close} = useDialogContext();
  const {id} = useRecordContext();
  const {selected: ids} = useListContext();
  const [mutate] = useMutate<{item: RecordType; items: RecordType[]}>(many ? 'DELETE_MANY' : 'DELETE_ONE', resource, {
    variables: {...params, ...(many ? {ids} : {id})}
  });

  const handleDelete = () => {
    if (typeof mutate === 'function') {
      mutate()
        .then((res) => {
          let itemName;
          if (res.data?.item) {
            itemName = res.data?.item.name || res.data?.item.id || '';
          }
          if (res.data?.items) {
            itemName = res.data?.items[0]?.name || res.data?.items[0]?.id || '';
          }
          if (itemName) {
            toast.success(`${resource}${itemName} has been deleted successfully!`);
            close();
            if (typeof onSuccess === 'function') {
              onSuccess();
            }
          }
        })
        .catch((error) => {
          if (error.message) {
            toast.error(error.message);
          }
        });
    }
  };

  return (
    <Button variant="contained" onClick={handleDelete}>
      Delete
    </Button>
  );
};

export default DeleteConfirmButton;
