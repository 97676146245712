import React from 'react';
// import CardFrame from '../../common/CardFrame';
// import {faRunning, faPlus} from '@fortawesome/free-solid-svg-icons';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import GroupRow from './GroupRow';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import List from 'components/list/List';
import {Group, PersonSearch} from '@mui/icons-material';
import {ListField} from 'components/list/Fields';
import CreateButton from 'components/forms/CreateButton';
import GroupForm from './GroupForm';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import ActionMenuItemEdit from 'components/list/actionMenu/ActionMenuItemEdit';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';
import ActionMenuItemDialog from 'components/list/actionMenu/ActionMenuItemDialog';
import GroupPreview from './GroupPreview';

const Groups = () => {
  // const [groups, setGroups] = useState(props.groups);
  // const [adding, setAdding] = useState(false);
  // const [newGroup, setNewGroup] = useState({ name: "" });

  // const onUpdateGroup = (group) => {
  //   const list = [...groups];
  //   list.forEach((element) => {
  //     if (element.id === group.id) {
  //       element.name = group.name;
  //     }
  //   });
  //   setGroups(list);
  //   onCancel();
  // };

  // const onCreateGroup = (group) => {
  //   setGroups((groups) => {
  //     groups.push(group);
  //     return groups;
  //   });
  //   onCancel();
  // };

  // const onDeleteGroup = (groupId) => {
  //   const list = groups.filter((element) => element.id !== groupId);
  //   setGroups(list);
  // };

  // const onCancel = () => {
  //   setAdding(false);
  //   setNewGroup({});
  // };

  return (
    <ResourceContextProvider resource="Group">
      <List
        hidePagination
        title="Groups"
        avatar={<Group />}
        toolbar={
          <React.Fragment>
            <CreateButton>
              <GroupForm />
            </CreateButton>
          </React.Fragment>
        }
      >
        <ListField source="name" label="Name" />
        <ActionMenu label="Actions" align="right" width="5%">
          <ActionMenuItemDialog label="Preview" icon={<PersonSearch />} confirmTitle="Preview Group" confirmContent={<GroupPreview />} />
          <ActionMenuItemEdit confirmContent={<GroupForm />} />
          <ActionMenuItemDelete />
        </ActionMenu>
      </List>
      {/* <CardFrame title="Group" icon={faRunning}>
        {groups.length === 0 && <div>No Groups</div>}
        {groups.length > 0 && (
          <div className="groups">
            <div className="row header">
              <div className="col-7">Group</div>
              <div className="col-1">Users</div>
              <div className="col-4">Actions</div>
            </div>
            {groups.map((group) => (
              <GroupRow key={group.id} group={group} onSave={onUpdateGroup} onDelete={onDeleteGroup} onCancel={onCancel} />
            ))}
          </div>
        )}
        {adding ? (
          <GroupRow group={newGroup} onSave={onCreateGroup} onCancel={onCancel} isEdit={true} />
        ) : (
          <>
            <button className="btn btn-sm btn-primary" onClick={() => setAdding(true)}>
              <FontAwesomeIcon icon={faPlus} /> Add
            </button>
          </>
        )}
      </CardFrame> */}
    </ResourceContextProvider>
  );
};

export default Groups;
