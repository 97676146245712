import {useContext} from 'react';
import {RecordContext, RecordType} from './RecordContext';

export const useRecordContext = <T extends RecordType>(): T => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const context = useContext<T>(RecordContext);

  return context;
};

export interface UseRecordContextParams<T extends RecordType> {
  record?: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
