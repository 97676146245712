import React from 'react';

import {TextInput} from 'components/forms/Inputs';
import {SubEventType} from 'components/SubEvent/SubEvents';
import {Button} from '@mui/material';
import ViewMap from '../ViewMap';
import GeoReferenceMap from '../GeoreferenceMap';
import {useWatch} from 'react-hook-form';

const EditMapForm = ({subEvent}: {subEvent: SubEventType}) => {
  const url = useWatch({name: 'url'});

  const [isEdit, setIsEdit] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <TextInput required fullWidth label="Name" name="name" />
      {!isEdit && (
        <React.Fragment>
          <Button onClick={() => setIsEdit(true)}>Edit Geo Map</Button>
          <ViewMap subEvent={subEvent} />
        </React.Fragment>
      )}
      {isEdit && <GeoReferenceMap imageSrcData={process.env.REACT_APP_UPLOADS + url} subEvent={subEvent} />}
    </React.Fragment>
  );
};

export default EditMapForm;
