import React from 'react';

import CardFrame from '../common/CardFrame';
import EventInfoRow from './EventInfoRow';

import Logos from '../Logo/Logos';
import {Grid} from '@mui/material';
import {useRecordContext} from 'components/record/useRecordContext';
import {Info} from '@mui/icons-material';
import EditButton from 'components/forms/EditButton';
import EventSettingsForm from './EventSettingsForm';

const EventSettings = () => {
  const {record} = useRecordContext();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={6} padding={2}>
          <CardFrame
            title="Event Settings"
            avatar={<Info />}
            action={
              <EditButton>
                <EventSettingsForm />
              </EditButton>
            }
          >
            <EventInfoRow title="Can Enter" source={record.canEnter ? 'true' : ''} />
            <EventInfoRow title="Auto Accept Entries" source={record.autoAcceptEntry ? 'true' : ''} />
            <EventInfoRow title="Analytic" source={record.analytic ? 'true' : ''} />

            {/* <EventInfoRow title="GPS Interval" source={record.gpsInterval} /> */}
            <EventInfoRow title="Delay" source={record.delay} />
            <EventInfoRow title="Use SportIdent" source={record.useSI} />
            <EventInfoRow title="Use Results" source={record.useResults ? 'true' : ''} />
            {record.useResults && <EventInfoRow title="Live Results CompetitorId" source={record.liveResultsCompetitionId} />}
          </CardFrame>
        </Grid>
        <Grid item xs={12} md={6} padding={2}>
          <Logos />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default EventSettings;
