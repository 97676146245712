import {TextInput, SwitchInput} from 'components/forms/Inputs';
import React from 'react';

const SubEventForm = () => {
  return (
    <React.Fragment>
      <TextInput required fullWidth label="Sub event" name="name" autoFocus />
      <TextInput fullWidth label="Abbreviation" name="sub" />
      <SwitchInput sx={{width: '100%'}} name="useGPS" label="useGPS" />
    </React.Fragment>
  );
};

export default SubEventForm;
