import React, {ReactNode, createContext} from 'react';

export type Identifier = string | number;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface RecordType<IdentifierType extends Identifier = Identifier> extends Record<string, any> {
  id: IdentifierType;
}

export type RecordContextType = {
  record: RecordType;
  id?: string | number;
  refresh?: () => void;
};

export const RecordContext = createContext<RecordContextType>({record: {id: 0}});

export const RecordContextProvider = <T extends RecordType>({children, value, id = value.id, refresh}: RecordContextProviderProps<T>) => (
  <RecordContext.Provider value={{record: value, id, refresh}}>{children}</RecordContext.Provider>
);

export interface RecordContextProviderProps<RecordType> {
  id?: string | number;
  refresh?: () => void;
  children: ReactNode;
  value: RecordType;
}
