import React from 'react';

import {useRecordContext} from 'components/record/useRecordContext';
import List from 'components/list/List';
import {BooleanField, DateField, ListField} from 'components/list/Fields';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';
import {Check, Map, Visibility, VisibilityOff} from '@mui/icons-material';
import ActionMenuItemUpdate from 'components/list/actionMenu/ActionMenuItemUpdate';
import {ActionMenuItemOrderDown, ActionMenuItemOrderUp} from 'components/list/actionMenu/ActionMenuItemsOrder';
import {LatLngLiteral} from 'leaflet';
import CreateButton from 'components/forms/CreateButton';
import MapForm from './Image/MapForm';
import ActionMenuItemEdit from 'components/list/actionMenu/ActionMenuItemEdit';
import EditMapForm from './Image/EditMapForm';

export type MapType = {id: number; name: string; topLeft: LatLngLiteral; topRight: LatLngLiteral; bottomLeft: LatLngLiteral; url: string};

const Maps = () => {
  const {record: subEvent} = useRecordContext();

  return (
    <ResourceContextProvider resource="Map" params={{subEventId: subEvent.id}}>
      <List
        bulkOptions={false}
        hidePagination
        title="Maps"
        avatar={<Map />}
        toolbar={
          <CreateButton label="Add New Map">
            <MapForm subEvent={subEvent} />
          </CreateButton>
        }
      >
        <ListField source="name" label="Name" />
        <DateField source="createdAt" label="Create At" />
        <BooleanField source="show" label="Visible">
          <Check />
        </BooleanField>
        <ActionMenu label="Actions" align="right" width="5%">
          <ActionMenuItemEdit confirmContent={<EditMapForm subEvent={subEvent} />} />
          <ActionMenuItemUpdate label="Show" icon={<Visibility />} data={{show: true}} />
          <ActionMenuItemUpdate label="Hide" icon={<VisibilityOff />} data={{show: false}} />
          <ActionMenuItemOrderUp />
          <ActionMenuItemOrderDown />
          <ActionMenuItemDelete />
        </ActionMenu>
      </List>
    </ResourceContextProvider>
  );
};

export default Maps;
