import React from 'react';
import {AutocompleteInput, AutocompleteInputProps} from './Inputs';
import {useFetch} from 'hooks/useFetchData';

type UserType = {id: number; name: string};

const AutocompleteResourceInput = (props: AutocompleteResourceInputProps) => {
  const {resource, ...rest} = props;
  const {data, loading} = useFetch<{items: UserType[]}>('GET_LIST', resource);
  const userOptions = React.useMemo(() => (data?.items || []).map((item) => ({id: item.id, label: item.name})), [data]);

  return <AutocompleteInput loading={loading} options={userOptions} {...rest} />;
};

export default AutocompleteResourceInput;

type AutocompleteResourceInputProps = Omit<AutocompleteInputProps<{id: number; label: string}>, 'options' | 'loading'> & {
  resource: string;
};
