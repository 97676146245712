import React from 'react';

const TabElement = (props: TabElementProps) => {
  const {children} = props;
  return <React.Fragment>{children}</React.Fragment>;
};

export default TabElement;

type TabElementProps = {
  value: string;
  label: string;
  children: React.ReactNode;
};
