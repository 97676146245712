import React from 'react';
import {Toolbar, Typography} from '@mui/material';
import {lighten} from '@mui/material/styles';
import {useListContext} from '../ListContext';
import {Identifier} from 'components/record/RecordContext';

const TableToolbar = (props: TableToolbarProps) => {
  const {selected} = useListContext();
  const {onChangeSelected, bulkActions} = props;

  React.useEffect(() => {
    if (typeof onChangeSelected === 'function') {
      onChangeSelected(selected);
    }
  }, [selected]);

  return (
    <Toolbar
      sx={{
        pl: {sm: 2},
        pr: {xs: 1, sm: 1},
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 3,
        ...(selected.length > 0 && {
          bgcolor: (theme) => lighten(theme.palette.primary.main, 1 - theme.palette.action.activatedOpacity)
        }),
        ...(selected.length === 0 && {
          display: 'none'
        })
      }}
    >
      {selected.length > 0 && (
        <Typography sx={{flex: '1 1 100%'}} color="inherit" variant="subtitle1" component="div">
          {selected.length} selected
        </Typography>
      )}
      {selected.length > 0 && bulkActions}
    </Toolbar>
  );
};

export default TableToolbar;

type TableToolbarProps = {
  bulkActions: React.ReactNode;
  onChangeSelected?: (selected: Identifier[]) => void;
};
