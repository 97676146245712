import React from 'react';
import {Polyline} from 'react-leaflet';

import {SubEventType} from 'components/SubEvent/SubEvents';
import {TrackPoint} from './SingleTrack';
import SubEventMapContainer from 'components/Control/SubEventMapContainer';

const ShowTrack = (props: ShowTrackProps) => {
  const {subEvent, color, track} = props;

  return (
    <SubEventMapContainer subEvent={subEvent} hideRoutes style={{width: '100%', height: '500px', position: 'relative'}}>
      <Polyline color={color} positions={track} />
    </SubEventMapContainer>
  );
};

export default ShowTrack;

type ShowTrackProps = {
  subEvent: SubEventType;
  color: string;
  track: TrackPoint[];
};
