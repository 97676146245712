import React from 'react';
import {
  AutocompleteElement,
  AutocompleteElementProps,
  DateTimePickerElement,
  DateTimePickerElementProps,
  FieldValues,
  SelectElement,
  SelectElementProps,
  SwitchElement,
  SwitchElementProps,
  TextFieldElement,
  TextFieldElementProps
} from 'react-hook-form-mui';

export const TextInput = (props: TextFieldElementProps) => <TextFieldElement size="small" margin="normal" {...props} />;

export const SelectInput = (props: SelectElementProps<FieldValues>) => <SelectElement size="small" margin="normal" {...props} />;

export const SwitchInput = (props: SwitchElementProps<FieldValues>) => <SwitchElement sx={{width: '100%'}} {...props} />;

export const DateTimePickerInput = <TInputDate,>(props: DateTimePickerElementProps<FieldValues, TInputDate, TInputDate> & {fullWidth?: boolean}) => {
  const {fullWidth = false, ...rest} = props;
  return <DateTimePickerElement inputProps={{size: 'small', margin: 'normal', fullWidth}} defaultValue={Date.now()} {...rest} />;
};

export const AutocompleteInput = <T,>(props: AutocompleteInputProps<T>) => {
  const {fullWidth = false, ...rest} = props;
  return <AutocompleteElement matchId autocompleteProps={{size: 'small', fullWidth}} textFieldProps={{size: 'small', margin: 'normal'}} {...rest} />;
};

export type AutocompleteInputProps<T> = AutocompleteElementProps<FieldValues, T, boolean, boolean> & {fullWidth?: boolean};
