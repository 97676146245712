import React from 'react';
import Button, {ButtonProps} from '@mui/material/Button';
import {styled} from '@mui/material/styles';

const Styled = {
  Button: styled(Button)(() => {
    return {
      backgroundSize: '8px 8px',
      backgroundPosition: '0 0, 4px 0, 4px -4px, 0px 4px',
      transition: 'none',
      boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
      border: 0,
      borderRadius: 4,
      width: '24px',
      aspectRatio: '1 / 1',
      height: '24px',
      minWidth: 0
    };
  })
};

export type ColorButtonProps = Omit<ButtonProps, 'children'> & {
  bgColor: string;
  disablePopover: boolean;
};

export type ColorButtonElement = (props: ColorButtonProps) => JSX.Element;

const ColorButton = (props: ColorButtonProps) => {
  const {bgColor, className, disablePopover, ...restButtonProps} = props;

  return (
    <Styled.Button
      disableTouchRipple
      style={{
        backgroundColor: bgColor,
        cursor: disablePopover ? 'default' : undefined
      }}
      className={`MuiColorInput-Button ${className || ''}`}
      {...restButtonProps}
    />
  );
};

export default ColorButton;
