import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/css/custom.scss';

const root = ReactDOM.createRoot(document.getElementById('container') as HTMLElement);

root.render(<App />);

serviceWorker.unregister();
