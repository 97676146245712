import {FormControl} from '@mui/material';
import FilePicker from 'components/common/FilePicker';
import {SelectInput, TextInput} from 'components/forms/Inputs';
import React from 'react';
import {useController} from 'react-hook-form';

const positionOptions = [
  {
    id: 'center',
    label: 'Center'
  },
  {
    id: 'left',
    label: 'Left'
  },
  {
    id: 'right',
    label: 'Right'
  }
];

const LogoForm = () => {
  const {
    field: {onChange: onChangeFile}
  } = useController({name: 'file'});

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const fileTypes = ['jpg', 'jpeg'];
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0] as File;
      const fileName = file.name || '';
      const extension = fileName.split('.').pop()?.toLowerCase() || ' ';
      if (fileTypes.indexOf(extension) > -1) {
        onChangeFile(file);
      }
    }
  };

  return (
    <React.Fragment>
      <TextInput required fullWidth label="Name" name="name" />
      <TextInput required fullWidth label="Width" name="width" type="number" />
      <SelectInput required fullWidth label="Position" name="position" options={positionOptions} />
      <FormControl fullWidth margin="normal">
        <FilePicker label="Upload Map Image" accept=".jpg, .jpeg" onFileChange={handleChange} />
      </FormControl>
    </React.Fragment>
  );
};

export default LogoForm;
