import React from 'react';
import {ImageOverlay} from 'react-leaflet';
import Leaflet from 'leaflet';
import UTM from '../../services/UTM';
import ImageOverlayRotated from '../MyEvents/Track/ImageOverlayRotated';
import MapContainer from '../common/MapContainer';
import {SubEventType} from 'components/SubEvent/SubEvents';
import {useController, useWatch} from 'react-hook-form';
import {LatLngBoundsLiteral} from 'leaflet';
import FilePicker from 'components/common/FilePicker';
import {toast} from 'react-toastify';
import {FormControl} from '@mui/material';

const utm = new UTM();

const JpwMap = ({imageSrcData, subEvent}: {imageSrcData?: string; subEvent: SubEventType}) => {
  const height = useWatch({name: 'height'});
  const width = useWatch({name: 'width'});
  const event = subEvent.event;

  const {
    field: {value: topLeft, onChange: setTopLeft}
  } = useController({name: 'topLeft'});

  const {
    field: {value: topRight, onChange: setTopRight}
  } = useController({name: 'topRight'});

  const {
    field: {value: bottomLeft, onChange: setBottomLeft}
  } = useController({name: 'bottomLeft'});

  const mapBounds: LatLngBoundsLiteral = React.useMemo(
    () => [
      [0, 0],
      [height, width]
    ],
    [height, width]
  );

  const {lat, lng} = event.location;
  const locationUTM = utm.convertLatLngToUtm(lat, lng, 10);

  const [isSetJpw, setIsSetJpw] = React.useState<boolean>(false);

  const handleJgwFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new window.FileReader();
      const file = e.target.files[0] as File;
      const fileName = file.name || '';
      const extension = fileName.split('.').pop()?.toLowerCase() || ' ';
      if (extension === 'jgw') {
        reader.onload = (e) => {
          try {
            const data = e.target?.result as string;
            if (!data) {
              return;
            }
            const lines = data.split('\n');
            const x = Number(lines[4]);
            const y = Number(lines[5]);
            const tLeft = [x, y];
            const tRight = [x + width * Number(lines[0]), y + width * Number(lines[1])];
            const bLeft = [x + height * Number(lines[2]), y + height * Number(lines[3])];
            const topLeft = utm.convertUtmToLatLng(tLeft[0], tLeft[1], locationUTM.ZoneNumber, locationUTM.ZoneLetter);
            const topRight = utm.convertUtmToLatLng(tRight[0], tRight[1], locationUTM.ZoneNumber, locationUTM.ZoneLetter);
            const bottomLeft = utm.convertUtmToLatLng(bLeft[0], bLeft[1], locationUTM.ZoneNumber, locationUTM.ZoneLetter);
            setIsSetJpw(true);

            setTopLeft(topLeft);
            setTopRight(topRight);
            setBottomLeft(bottomLeft);
          } catch (err) {
            console.log(err);
          }
        };
        reader.readAsText(file);
      } else {
        toast.error('Wrong file type');
      }
    }
  };

  if (!imageSrcData) {
    return null;
  }

  return (
    <React.Fragment>
      {!isSetJpw ? (
        <React.Fragment>
          <MapContainer
            className="leaflet-crosshair"
            bounds={mapBounds}
            style={{width: '100%', height: '650px', position: 'relative'}}
            crs={Leaflet.CRS.Simple}
            minZoom={-3}
            noTileLayer
          >
            <ImageOverlay url={imageSrcData} bounds={mapBounds} />
          </MapContainer>
          <FormControl fullWidth margin="normal">
            <FilePicker label="Select jgw file" accept=".jgw" onFileChange={handleJgwFile} />
          </FormControl>{' '}
        </React.Fragment>
      ) : (
        <MapContainer
          center={event.location}
          zoom={event.zoom}
          className="leaflet-crosshair"
          style={{width: '100%', height: '650px', position: 'relative'}}
        >
          <ImageOverlayRotated topLeft={topLeft} topRight={topRight} bottomLeft={bottomLeft} opacity={0.5} image={imageSrcData} />
        </MapContainer>
      )}
    </React.Fragment>
  );
};

export default JpwMap;
