import {MoreVert} from '@mui/icons-material';
import {IconButton, Popover, TableCell} from '@mui/material';
import React, {useState} from 'react';
import {useListContext} from '../ListContext';

const ActionMenu = (props: ActionMenuProps) => {
  const {refetch} = useListContext();
  const {align = 'left', children} = props;
  const [open, setOpen] = useState<(EventTarget & HTMLButtonElement) | null>(null);

  const handleOpenMenu: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <TableCell align={align}>
        <IconButton onClick={handleOpenMenu} size="small">
          <MoreVert />
        </IconButton>
      </TableCell>
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        PaperProps={{
          sx: {width: 140}
        }}
      >
        {React.Children.map(children, (field, index) => {
          const element = field as React.ReactElement;
          return (
            <element.type
              {...element.props}
              key={index}
              onClose={handleCloseMenu}
              onSuccess={() => {
                handleCloseMenu();
                typeof refetch === 'function' && refetch();
              }}
            />
          );
        })}
      </Popover>
    </>
  );
};

type ActionMenuProps = {
  label?: string;
  width?: string;
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify' | undefined;
  children?: Array<React.ReactElement> | React.ReactElement;
};

export default ActionMenu;
