import React from 'react';
import {ImageOverlay, Polyline} from 'react-leaflet';
import Leaflet, {LatLngBoundsLiteral, LatLngLiteral, LeafletMouseEventHandlerFn} from 'leaflet';
import UTM from '../../services/UTM';
import ImageOverlayRotated from '../MyEvents/Track/ImageOverlayRotated';
import {angle, plus, rotate, toVector, transform, vectorLen, orient} from '../common/TransformVectors';
import MapEventHandlers from '../common/MapEventHandlers';
import DraggableMarkers from '../common/DraggableMarker';
import MapContainer from '../common/MapContainer';
import {useController, useWatch} from 'react-hook-form';
import {SubEventType} from 'components/SubEvent/SubEvents';
import FilePicker from 'components/common/FilePicker';
import {convertGpxToTrackArray} from 'services/gpxUtils';
import {TrackPoint} from 'components/MyEvents/Track/SingleTrack';
import {toast} from 'react-toastify';

const utm = new UTM();

const GeoReferenceMap = ({imageSrcData, subEvent}: {imageSrcData?: string; subEvent: SubEventType}) => {
  const height = useWatch({name: 'height'});
  const width = useWatch({name: 'width'});
  const event = subEvent.event;

  const {
    field: {value: topLeft, onChange: setTopLeft}
  } = useController({name: 'topLeft'});

  const {
    field: {value: topRight, onChange: setTopRight}
  } = useController({name: 'topRight'});

  const {
    field: {value: bottomLeft, onChange: setBottomLeft}
  } = useController({name: 'bottomLeft'});

  const mapBounds: LatLngBoundsLiteral = React.useMemo(
    () => [
      [0, 0],
      [height, width]
    ],
    [height, width]
  );
  const {lat, lng} = event.location;
  const locationUTM = utm.convertLatLngToUtm(lat, lng, 10);

  const [mapMarkers, setMapMarkers] = React.useState<LatLngLiteral[]>([]);

  const [isSetMapMarkers, setIsSetMapMarkers] = React.useState(false);
  const [gpx, setGPX] = React.useState<TrackPoint[]>([]);
  const [markers, setMarkers] = React.useState<LatLngLiteral[]>([]);

  const handleMapClick: LeafletMouseEventHandlerFn = (e) => {
    if (mapMarkers.length < 2) {
      setMapMarkers((mapMarkers) => [...mapMarkers, e.latlng]);
    }
  };
  const updateMapMarkerPosition = (index: number) => {
    return (value: LatLngLiteral) => {
      setMapMarkers((mapMarkers) => {
        mapMarkers[index] = value;
        return [...mapMarkers];
      });
    };
  };

  const clickOkMapMarkers = () => {
    setIsSetMapMarkers(true);
  };

  const handleClick: LeafletMouseEventHandlerFn = (e) => {
    if (markers.length < 2) {
      setMarkers((markers) => [...markers, e.latlng]);
    }
  };

  const updatePosition = (index: number) => {
    return (value: LatLngLiteral) => {
      setMarkers((markers) => {
        markers[index] = value;
        return [...markers];
      });
    };
  };

  React.useEffect(() => {
    if (markers.length === 2) {
      updateMap();
    }
  }, [markers]);

  const updateMap = () => {
    if (markers.length === 2) {
      const point1 = mapMarkers[0];
      const point2 = mapMarkers[1];

      const marker1 = markers[0];
      const marker2 = markers[1];
      const pp1 = utm.convertLatLngToUtm(marker1.lat, marker1.lng, 10);
      const pp2 = utm.convertLatLngToUtm(marker2.lat, marker2.lng, 10);
      const p1 = {
        lng: pp1.Easting,
        lat: pp1.Northing
      };
      const p2 = {
        lng: pp2.Easting,
        lat: pp2.Northing
      };

      const vec1 = toVector(point1, point2);
      const vec = toVector(p1, p2);

      let a = Math.acos(angle(vec1, vec));
      if (orient({lng: 0, lat: 0}, vec1, vec) < 0) a = -a;
      const k = vectorLen(vec) / vectorLen(vec1);

      const tLeft = plus(p1, rotate(transform(toVector(point1, {lng: 0, lat: height}), k), a));
      const tRight = plus(
        p1,
        rotate(
          transform(
            toVector(point1, {
              lng: width,
              lat: height
            }),
            k
          ),
          a
        )
      );
      const bLeft = plus(p1, rotate(transform(toVector(point1, {lat: 0, lng: 0}), k), a));
      const topLeft = utm.convertUtmToLatLng(tLeft.lng, tLeft.lat, locationUTM.ZoneNumber, locationUTM.ZoneLetter);
      const topRight = utm.convertUtmToLatLng(tRight.lng, tRight.lat, locationUTM.ZoneNumber, locationUTM.ZoneLetter);
      const bottomLeft = utm.convertUtmToLatLng(bLeft.lng, bLeft.lat, locationUTM.ZoneNumber, locationUTM.ZoneLetter);

      setTopLeft(topLeft);
      setTopRight(topRight);
      setBottomLeft(bottomLeft);
    }
  };

  const handleGpxFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new window.FileReader();
    const files = e.target.files;
    if (files && files.length > 0) {
      setGPX([]);
      const file = files[0];
      const fileName = file.name;
      const extension = fileName.split('.').pop()?.toLowerCase();
      if (extension === 'gpx') {
        reader.onload = (e) => {
          setGPX(convertGpxToTrackArray(e.target?.result as string));
        };
        reader.readAsText(file);
      } else {
        toast.error('Тhe file is not in correct format!');
      }
    }
  };

  if (!imageSrcData) {
    return null;
  }

  return (
    <React.Fragment>
      {!isSetMapMarkers && (
        <React.Fragment>
          <h3>Insert Map markers</h3>
          <MapContainer
            className="leaflet-crosshair"
            bounds={mapBounds}
            style={{width: '100%', height: '650px', position: 'relative'}}
            crs={Leaflet.CRS.Simple}
            minZoom={-3}
            noTileLayer
          >
            <MapEventHandlers click={handleMapClick} />
            <ImageOverlay url={imageSrcData} bounds={mapBounds} />
            {mapMarkers.map((marker, i) => (
              <DraggableMarkers key={i} position={marker} onUpdate={updateMapMarkerPosition(i)} />
            ))}
          </MapContainer>
          {mapMarkers.length === 2 && (
            <button className="btn btn-primary" onClick={clickOkMapMarkers}>
              OK
            </button>
          )}
        </React.Fragment>
      )}
      {isSetMapMarkers && (
        <React.Fragment>
          <MapContainer
            center={event.location}
            zoom={event.zoom}
            className="leaflet-crosshair"
            style={{width: '100%', height: '650px', position: 'relative'}}
          >
            <MapEventHandlers click={handleClick} />
            {topLeft && topRight && bottomLeft && (
              <ImageOverlayRotated topLeft={topLeft} topRight={topRight} bottomLeft={bottomLeft} opacity={0.5} image={imageSrcData} />
            )}
            {gpx.length > 0 && <Polyline color="green" opacity={0.8} positions={gpx} />}
            {markers.map((marker, i) => (
              <DraggableMarkers key={i} position={marker} onUpdate={updatePosition(i)} />
            ))}
          </MapContainer>
          <FilePicker label="Upload track" accept=".gpx" onFileChange={handleGpxFileChange} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default GeoReferenceMap;
