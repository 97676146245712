import React from 'react';
import {Delete as DeleteIcon} from '@mui/icons-material';
import {useResourceContext} from 'components/record/ResourceContext';
import {useRecordContext} from 'components/record/useRecordContext';
import DeleteConfirmButton from 'components/forms/DeleteConfirmButton';
import ActionMenuItemConfirmDialog, {ActionMenuItemConfirmDialogProps} from './ActionMenuItemConfirmDialog';

const ActionMenuItemDelete = (props: ActionMenuItemConfirmDialogProps): JSX.Element | null => {
  const {resource} = useResourceContext();
  const record = useRecordContext();

  const {
    label = 'Delete',
    confirmTitle = `Delete ${resource} item #${record.id}`,
    confirmContent = 'Are you sure you want to delete this item?',
    onSuccess,
    sx,
    icon = <DeleteIcon />,
    ...rest
  } = props;

  return (
    <React.Fragment>
      <ActionMenuItemConfirmDialog
        {...rest}
        label={label}
        confirmContent={confirmContent}
        confirmTitle={confirmTitle}
        icon={icon}
        sx={{color: 'error.main', ...sx}}
        primaryButton={<DeleteConfirmButton onSuccess={onSuccess} />}
      />
    </React.Fragment>
  );
};

export default ActionMenuItemDelete;
