const setAccessToken = (access_token) => {
  localStorage.setItem('access_token', access_token);
};

const setTokens = (tokenObj) => {
  localStorage.setItem('access_token', tokenObj.access_token);
  localStorage.setItem('refresh_token', tokenObj.refresh_token);
  localStorage.setItem('role', tokenObj.user.role.name);
};

const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

const getUserRole = () => {
  return localStorage.getItem('role');
};

const clearTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('role');
};

export default {
  setTokens,
  setAccessToken,
  clearTokens,
  getAccessToken,
  getRefreshToken,
  getUserRole,
};
