import React from 'react';
import ImportTrack from './ImportTrack';
import ShowTrack from './ShowTrack';
import {useRecordContext} from 'components/record/useRecordContext';
import {SubEventType} from 'components/SubEvent/SubEvents';
import {useFetch} from 'hooks/useFetchData';
import {LatLngLiteral} from 'leaflet';
import {CircularProgress} from '@mui/material';
import DeleteButton from 'components/forms/DeleteButton';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import {RecordContextProvider} from 'components/record/RecordContext';
import EditButton from 'components/forms/EditButton';
import EditTrackForm from './EditTrackForm';
import ImportTrackFromGPS from './ImportTrackFromGPS';

const STAGE = {
  SHOW: 'show',
  EDIT: 'edit',
  EXPORT: 'export',
  IMPORT: 'import'
};

export type TrackPoint = LatLngLiteral & {time: number};

const SingleTrack = (props: SingleTrackProps) => {
  const {subEvent} = props;
  const {record: competitor} = useRecordContext();

  const subEventId = subEvent.id;
  const competitorId = competitor.id;

  const [stage, setStage] = React.useState<string>();
  const [track, setTrack] = React.useState<TrackPoint[]>([]);

  const {data, loading, refetch} = useFetch<{item: {track: TrackPoint[]}}>('GET_ONE', 'CompetitorTrack', {
    variables: {
      subEventId,
      id: competitorId
    }
  });

  React.useEffect(() => setTrack(data?.item.track || []), [data]);
  React.useEffect(() => setStage(track.length > 0 ? STAGE.SHOW : STAGE.IMPORT), [track]);

  if (loading) return <CircularProgress />;

  const onSuccess = () => {
    setStage(STAGE.IMPORT);
  };

  if (subEvent.event.status === 'live') {
    return <React.Fragment>Live Event!</React.Fragment>;
  }

  return (
    <ResourceContextProvider resource="CompetitorTrack" params={{subEventId: subEvent.id, id: competitor.id}}>
      {stage === STAGE.IMPORT && (
        <React.Fragment>
          <ImportTrack subEvent={subEvent} competitor={competitor} callback={refetch} />
          <ImportTrackFromGPS subEvent={subEvent} competitor={competitor} callback={refetch} />
        </React.Fragment>
      )}
      {stage === STAGE.SHOW && (
        <React.Fragment>
          <ShowTrack subEvent={subEvent} track={track} color={competitor?.color} />
          <RecordContextProvider refresh={refetch} value={{track, id: competitorId}} id={competitorId}>
            <EditButton>
              <EditTrackForm subEvent={subEvent} color={competitor.color} data={track} />
            </EditButton>
            <DeleteButton onSuccess={onSuccess}>Are you sure you want to delete track?</DeleteButton>
          </RecordContextProvider>
        </React.Fragment>
      )}
      {/* TODO: Export track */}
    </ResourceContextProvider>
  );
};

export default SingleTrack;

type SingleTrackProps = {
  subEvent: SubEventType;
};
