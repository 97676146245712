import React, {MouseEventHandler, ReactElement} from 'react';
import {Icon, MenuItem, SxProps, Theme} from '@mui/material';

const ActionMenuItem = (props: ActionMenuItemProps) => {
  const {onClick, label, sx = {}, icon = <Icon />} = props;
  return (
    <MenuItem onClick={onClick} sx={{...sx}}>
      {icon}
      {label}
    </MenuItem>
  );
};

export default ActionMenuItem;

export type ActionMenuItemProps = {
  label?: string;
  icon?: ReactElement;
  onSuccess?: () => void;
  callback?: () => void;
  onClick?: MouseEventHandler;
  sx?: SxProps<Theme>;
};
