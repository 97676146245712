import React from 'react';
import ButtonDialog from './ButtonDialog';
import {useResourceContext} from 'components/record/ResourceContext';
import {Delete} from '@mui/icons-material';
import DeleteConfirmButton from './DeleteConfirmButton';
import {useListContext} from 'components/list/ListContext';

const BulkDelete = (props: BulkDeleteProps) => {
  const {resource} = useResourceContext();
  const {refetch, setSelected} = useListContext();
  const {label = 'Delete', confirmTitle = `Delete ${resource} items`, ...rest} = props;

  const onSuccess = () => {
    setSelected([]);
    refetch();
  };

  return (
    <ButtonDialog
      buttonLabel={label}
      variant="text"
      maxWidth="sm"
      startIcon={<Delete />}
      dialogTitle={confirmTitle}
      primaryButton={<DeleteConfirmButton onSuccess={onSuccess} many />}
      {...rest}
    >
      Are you sure you want to delete this items?
    </ButtonDialog>
  );
};

type BulkDeleteProps = {
  label?: string;
  confirmTitle?: string;
  confirmContent?: React.ReactNode;
};

export default BulkDelete;
