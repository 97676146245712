export function vectorLen (p1) {
  return Math.sqrt((p1.lat * p1.lat) + (p1.lng * p1.lng))
}

export function transform (vec, k) {
  return {
    lng: vec.lng * k,
    lat: vec.lat * k
  }
}

export function plus (point, vec) {
  return {
    lng: point.lng + vec.lng,
    lat: point.lat + vec.lat
  }
}

export function toVector (p1, p2) {
  return {
    lng: p2.lng - p1.lng,
    lat: p2.lat - p1.lat
  }
}

export function angle (vec1, vec2) {
  return ((vec1.lat * vec2.lat) + (vec1.lng * vec2.lng)) / (vectorLen(vec1) * vectorLen(vec2))
}

export function rotate (vec, angle) {
  let cos = Math.cos(angle)
  let sin = Math.sin(angle)
  return {
    lng: (vec.lng * cos) - (vec.lat * sin),
    lat: (vec.lng * sin) + (vec.lat * cos)
  }
}

export function orient (p1, p2, p3) {
  return 0.5 * (
    (p1.lng - p2.lng) * (p1.lat + p2.lat) +
    (p2.lng - p3.lng) * (p2.lat + p3.lat) +
    (p3.lng - p1.lng) * (p3.lat + p1.lat)
  )
}
