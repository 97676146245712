import {DragEndEventHandlerFn, LatLngLiteral} from 'leaflet';
import React, {useMemo} from 'react';
import {Marker, MarkerProps} from 'react-leaflet';

type DraggableMarkersProps = MarkerProps & {
  onUpdate?: (latlng: LatLngLiteral) => void;
};

const DraggableMarkers = (props: DraggableMarkersProps) => {
  const {position, draggable = true, onUpdate, ...rest} = props;

  const dragend: DragEndEventHandlerFn = (event) => {
    if (event.target !== null && typeof onUpdate === 'function') {
      onUpdate(event.target.getLatLng());
    }
  };

  const eventHandlers = useMemo(
    () => ({
      dragend
    }),
    []
  );

  if (!position) return null;

  return <Marker draggable={draggable} eventHandlers={eventHandlers} position={position} {...rest}></Marker>;
};

export default DraggableMarkers;
