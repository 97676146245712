import React from 'react';

import {useRecordContext} from 'components/record/useRecordContext';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import List from 'components/list/List';
import {ListField} from 'components/list/Fields';
import CreateButton from 'components/forms/CreateButton';
import LogoForm from './LogoForm';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';

const Logos = () => {
  const {record: event} = useRecordContext();

  return (
    <ResourceContextProvider resource="Logo" params={{slug: event.slug}}>
      <List
        title="Logos"
        hidePagination
        toolbar={
          <React.Fragment>
            <CreateButton>
              <LogoForm />
            </CreateButton>
          </React.Fragment>
        }
      >
        <ListField source="name" label="Name" />
        <ActionMenu label="Actions" align="right" width="5%">
          <ActionMenuItemDelete />
        </ActionMenu>
      </List>
    </ResourceContextProvider>
  );
};

export default Logos;
