import React from 'react';
// import { Link } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlus, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

// import CardFrame from '../common/CardFrame'
// import WaitingEntries from './WaitingEntries'
// import { useQuery } from '@apollo/client'
// import { DASHBOARD_QUERY } from '../../services/gqlQueries'
// import Loader from '../common/Loader'
// import TableEvents from '../MyEvents/TableEvents'

const Dashboard = () => {
  // const {loading, data} = useQuery(DASHBOARD_QUERY, {
  //   variables: {
  //     page: 1,
  //     size: 5
  //   }
  // })

  // if (loading) return <Loader loading={loading} />

  return (
    <div className="container-fluid row">
      {/* <div className='col-12 col-lg-6'>
        <CardFrame title='My Last Events' icon={faCalendarAlt}>
          <div className='row_right'>
            <Link className='btn btn-sm btn-primary' to={`/my_events/add`}><FontAwesomeIcon icon={faPlus} /> Create New Event</Link>
          </div>
          <TableEvents events={data.myEvents} />
          <Link to={`/my_events/`}>More Events ...</Link>
        </CardFrame>
      </div>
      <div className='col-12 col-lg-6'>
        <WaitingEntries entries={data.waitingEntries}/>
      </div> */}
    </div>
  );
};

export default Dashboard;
