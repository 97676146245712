import React from 'react';
import {EventType} from 'components/SubEvent/SubEvents';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import List from 'components/list/List';
import {ListField} from 'components/list/Fields';
import CreateButton from 'components/forms/CreateButton';
import AutocompleteResourceInput from 'components/forms/AutocompleteResourceInput';
import {TextInput} from 'components/forms/Inputs';

const EntriesEmail = ({event}: {event: EventType}) => {
  const {slug} = event;

  return (
      <ResourceContextProvider resource="EntryData" params={{slug}}>
      <List
        hidePagination
        identifier="email"
        bulkOptions
        toolbar={
          <React.Fragment>
            <CreateButton label="Add user" dialogTitle="Add from User" many>
              <AutocompleteResourceInput label="Users" name="usersIds" multiple resource="User" />
            </CreateButton>
            <CreateButton label="Add group" dialogTitle="Add from Group" many>
              <AutocompleteResourceInput label="Groups" name="groupsIds" multiple resource="Group" />
            </CreateButton>
            <CreateButton label="Add emails" dialogTitle="Add from Email" many>
              <TextInput label="Emails" name="emails" fullWidth />
            </CreateButton>
          </React.Fragment>
        }
      >
        <ListField source="name" label="Name" />
        <ListField source="email" label="Email" />
      </List>
    </ResourceContextProvider>
  );
};

export default EntriesEmail;
