import {TrackPoint} from 'components/MyEvents/Track/SingleTrack';
import xmldom from 'xmldom';
import togeojson from '@mapbox/togeojson';

export const convertGpxToTrackArray = (source: string): TrackPoint[] => {
  const gpx = new xmldom.DOMParser().parseFromString(source);
  const c = togeojson.gpx(gpx);
  const geojson = c.features[0];
  const size = geojson.geometry.coordinates.length;
  const data: TrackPoint[] = [];
  for (let i = 0; i < size; i++) {
    data.push({
      lng: geojson.geometry.coordinates[i][0],
      lat: geojson.geometry.coordinates[i][1],
      alt: geojson.geometry.coordinates[i][2],
      time: new Date(geojson.properties.coordTimes[i]).getTime()
    });
  }

  return data;
};
