import React from 'react';
import {Delete} from '@mui/icons-material';
import ButtonDialog from 'components/forms/ButtonDialog';
import {useResourceContext} from 'components/record/ResourceContext';
import DeleteConfirmButton from './DeleteConfirmButton';

const DeleteButton = (props: DeleteButtonProps) => {
  const {resource} = useResourceContext();
  const {children, onSuccess, label = 'Delete', confirmTitle = `Delete ${resource} item`, ...rest} = props;

  return (
    <ButtonDialog
      buttonLabel={label}
      variant="outlined"
      startIcon={<Delete />}
      dialogTitle={confirmTitle}
      maxWidth="sm"
      primaryButton={<DeleteConfirmButton onSuccess={onSuccess} />}
      {...rest}
    >
      {children}
    </ButtonDialog>
  );
};

type DeleteButtonProps = {
  children: React.ReactNode;
  label?: string;
  confirmTitle?: string;
  onSuccess?: () => void;
  confirmContent?: React.ReactNode;
};

export default DeleteButton;
