import React, {useState} from 'react';
import ActionMenuItem, {ActionMenuItemProps} from './ActionMenuItem';
import CustomDialog from 'components/forms/CustomDialog';
import {DialogContextProvider} from 'components/dialog/DialogContext';

const ActionMenuItemDialog = (props: ActionMenuItemDialogProps): JSX.Element | null => {
  const {label, confirmTitle, confirmContent, sx, icon, onClose} = props;

  const [open, setOpen] = useState(false);

  const handleDialogOpen: React.MouseEventHandler = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  return (
    <React.Fragment>
      <ActionMenuItem onClick={handleDialogOpen} label={label} sx={sx} icon={icon} />
      <DialogContextProvider>
        <CustomDialog dialogTitle={confirmTitle} secondaryButton={<React.Fragment></React.Fragment>} open={open} resetDialog={handleClose}>
          {confirmContent}
        </CustomDialog>
      </DialogContextProvider>
    </React.Fragment>
  );
};

export default ActionMenuItemDialog;

type ActionMenuItemDialogProps = ActionMenuItemProps & {
  confirmTitle: React.ReactNode;
  confirmContent: React.ReactNode;
  onClose?: () => void;
};
