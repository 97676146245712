import React from 'react';
// import Modal from "react-modal";

import {ApolloClient, ApolloProvider, InMemoryCache, ApolloLink, fromPromise, gql} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'leaflet-gpx';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/sidebar.css';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {ToastContainer} from 'react-toastify';

import MainRouter from './routes/Main';
import LocalStorageService from './services/LocalStorageService';
import {ThemeProvider, createTheme} from '@mui/material';
import {cyan} from '@mui/material/colors';

const GET_TOKEN_QUERY = gql`
  query GetToken($refresh: String!) {
    getToken(refresh: $refresh)
  }
`;

const getNewToken = async () => {
  const {data} = await apolloClient.query({
    query: GET_TOKEN_QUERY,
    variables: {
      refresh: LocalStorageService.getRefreshToken()
    }
  });
  LocalStorageService.setAccessToken(data.getToken);
  return data.getToken;
};

const errorLink = onError(({graphQLErrors, operation, forward}) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          return fromPromise(
            getNewToken().catch(() => {
              // Handle token refresh errors e.g clear stored tokens, redirect to login
              return;
            })
          )
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              const oldHeaders = operation.getContext().headers;
              // modify the operation context with a new token
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`
                }
              });

              // retry the request, returning the new observable
              return forward(operation);
            });
      }
    }
  }
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL
});

const authLink = setContext((_, {headers}) => {
  const accessToken = LocalStorageService.getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  };
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

// Modal.setAppElement("#container");

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#ff0066'
    },
    secondary: {
      main: cyan[500]
    }
  }
});

const App = () => (
  <ApolloProvider client={apolloClient}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={defaultTheme}>
        <MainRouter />
        <ToastContainer position="top-right" autoClose={3000} theme="light" />
      </ThemeProvider>
    </LocalizationProvider>
  </ApolloProvider>
);

export default App;
