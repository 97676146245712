import AutocompleteResourceInput from 'components/forms/AutocompleteResourceInput';
import React from 'react';

const GroupUsersForm = () => {
  return (
    <React.Fragment>
      <AutocompleteResourceInput label="User" name="usersIds" multiple resource="User" />
    </React.Fragment>
  );
};

export default GroupUsersForm;
