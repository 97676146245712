import React from 'react';

import Dashboard from '../components/Dashboard/Dashboard';
import Logout from 'components/Auth/Logout';
import LoginPage from 'components/Auth/LoginPage';

import AllDevicesPage from '../components/Devices/AllDevicesPage';
import AllUsersPage from '../components/User/AllUsersPage';
import UsersPage from '../components/User/UsersPage';

import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import AdminRoute from './AdminRoute';
import {MyEventsRoutes, MySubEventsRoutes} from './EventsRoutes';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import DevicesPage from 'components/Devices/DevicesPage';

const MainRouter = () => {
  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <AdminRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: '/',
          element: <Dashboard />
        },
        MyEventsRoutes,
        MySubEventsRoutes,
        {
          path: '/my_devices',
          element: (
            <ResourceContextProvider resource="Device">
              <DevicesPage />
            </ResourceContextProvider>
          )
        },
        {
          path: '/users',
          element: <UsersPage />
        },
        {
          path: '/all_devices',
          element: <AllDevicesPage />
        },
        {
          path: '/all_users',
          element: <AllUsersPage />
        },
        {
          path: '/logout',
          element: <Logout />
        }
      ]
    }
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: '/login',
      element: <LoginPage />
    }
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([...routesForNotAuthenticatedOnly, ...routesForAuthenticatedOnly]);

  return <RouterProvider router={router} />;
};

export default MainRouter;
