import L from 'leaflet';
import iconCenterUrl from './center_icon.png';
import iconCircleUrl from './circle_icon.png';
import iconSquareUrl from './square_icon.png';
import iconTriangleUrl from './triangle_icon.png';

const iconCenter = new L.Icon({
  iconUrl: iconCenterUrl,
  iconRetinaUrl: iconCenterUrl,
  iconSize: new L.Point(50, 50),
  className: 'leaflet-marker-icon'
});

const baseOptions = {
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(40, 40),
  className: 'leaflet-marker-icon'
};

const iconCircle = new L.Icon({
  iconUrl: iconCircleUrl,
  iconRetinaUrl: iconCircleUrl,
  ...baseOptions
});

const iconTriangle = new L.Icon({
  iconUrl: iconTriangleUrl,
  iconRetinaUrl: iconTriangleUrl,
  ...baseOptions
});

const iconSquare = new L.Icon({
  iconUrl: iconSquareUrl,
  iconRetinaUrl: iconSquareUrl,
  ...baseOptions
});

export type IconType = 'default' | 'center' | 'circle' | 'square' | 'triangle';

export default {
  default: new L.Icon.Default(),
  center: iconCenter,
  circle: iconCircle,
  square: iconSquare,
  triangle: iconTriangle
};
