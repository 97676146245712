import {DocumentNode, MutationHookOptions, QueryHookOptions, useMutation, useQuery} from '@apollo/client';
import QUERIES from 'services/gqlQueries';
import pluralize from 'pluralize';

export const useFetch = <T,>(method: string, resource: string, options?: QueryHookOptions) => {
  let queryName = '';
  switch (method) {
    case 'GET_LIST':
      queryName = `get${pluralize(resource)}`;
      break;
    case 'GET_ONE':
      queryName = `get${resource}`;
      break;
  }
  const query = QUERIES[queryName] as DocumentNode;
  console.log(queryName, query, options);
  if (query) {
    return useQuery<T>(query, options);
  }

  return {error: 'Not found query', data: null, loading: false, refetch: () => {}};
};

export const useMutate = <T,>(method: string, resource: string, options?: MutationHookOptions) => {
  let queryName = '';
  switch (method) {
    case 'CREATE_ONE':
      queryName = `create${resource}`;
      break;
    case 'CREATE_MANY':
      queryName = `createMany${pluralize(resource)}`;
      break;
    case 'UPDATE_ONE':
      queryName = `update${resource}`;
      break;
    case 'UPDATE_MANY':
      queryName = `updateMany${pluralize(resource)}`;
      break;
    case 'DELETE_ONE':
      queryName = `delete${resource}`;
      break;
    case 'DELETE_MANY':
      queryName = `deleteMany${pluralize(resource)}`;
      break;
    default:
      queryName = `${method}${resource}`;
  }
  const mutate = QUERIES[queryName] as DocumentNode;
  console.log(queryName, mutate, options);
  if (mutate) {
    return useMutation<T>(mutate, options);
  }

  return [];
};
