import React, {forwardRef} from 'react';
import MapContainer, {MapContainerProps} from 'components/common/MapContainer';
import {Map} from 'leaflet';
import ImageOverlayRotated from 'components/MyEvents/Track/ImageOverlayRotated';
import {Polyline} from 'react-leaflet';
import {SubEventType} from 'components/SubEvent/SubEvents';
import {MapType} from 'components/Map/Maps';
import {RouteType} from 'components/Map/Routes';

const SubEventMapContainer = forwardRef((props: SubEventMapContainerProps, ref: React.ForwardedRef<Map>) => {
  const {children, subEvent, hideMaps = false, hideRoutes = false, ...rest} = props;
  return (
    <MapContainer center={subEvent.event.location} zoom={subEvent.event.zoom} {...rest} ref={ref}>
      {subEvent.routes &&
        !hideRoutes &&
        subEvent.routes.map((route: RouteType) => <Polyline key={route.id} pathOptions={{color: route.color}} positions={route.track} />)}
      {subEvent.maps &&
        !hideMaps &&
        subEvent.maps.map((m: MapType) => (
          <ImageOverlayRotated
            key={m.id}
            topLeft={m.topLeft}
            topRight={m.topRight}
            bottomLeft={m.bottomLeft}
            opacity={1}
            image={`${process.env.REACT_APP_UPLOADS}/${m.url}`}
          />
        ))}
      {children}
    </MapContainer>
  );
});

SubEventMapContainer.displayName = 'SubEventMapContainer';

export default SubEventMapContainer;

type SubEventMapContainerProps = MapContainerProps & {
  subEvent: SubEventType;
  hideMaps?: boolean;
  hideRoutes?: boolean;
};
