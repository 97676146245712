import React, {ReactNode} from 'react';
import {useDialogContext} from 'components/dialog/DialogContext';
import {useResourceContext} from 'components/record/ResourceContext';
import {useMutate} from 'hooks/useFetchData';
import {FormContainer, SubmitHandler} from 'react-hook-form-mui';
import {toast} from 'react-toastify';
import {RecordType} from 'components/record/RecordContext';

const CreateDialogBody = ({children, onSuccess, many = false}: {children: ReactNode; onSuccess?: () => void; many?: boolean}) => {
  const {resource, params} = useResourceContext();
  const {close} = useDialogContext();
  const [mutate] = useMutate<{item?: RecordType; items: RecordType[]}>(many ? 'CREATE_MANY' : 'CREATE_ONE', resource, {
    variables: params,
    context: {
      headers: {
        'apollo-require-preflight': true
      }
    }
  });

  const handleCreate: SubmitHandler<React.FormEvent<HTMLFormElement>> = (payload) => {
    if (typeof mutate === 'function') {
      mutate({variables: {payload}})
        .then((res) => {
          let itemName;
          if (res.data?.item) {
            itemName = res.data?.item.name || res.data?.item.id || '';
          }
          if (res.data?.items) {
            itemName = res.data?.items[0]?.name || res.data?.items[0]?.id || '';
          }
          if (itemName) {
            toast.success(`${resource}${itemName} has been created successfully!`);
            if (onSuccess && typeof onSuccess === 'function') {
              onSuccess();
            }
            close();
          }
        })
        .catch((error) => {
          if (error.message) {
            toast.error(error.message);
          }
        });
    }
  };

  return (
    <FormContainer FormProps={{id: `create_${resource}`, noValidate: true}} onSuccess={handleCreate}>
      {children}
    </FormContainer>
  );
};

export default CreateDialogBody;
