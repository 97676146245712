import {LatLngLiteral} from 'leaflet';

const datumNameDefault = 'WGS 84';

class UTMLatLng {
  private a: number;
  private eccSquared: number;
  private status: boolean;

  constructor(private datumName: string = datumNameDefault) {
    this.status = false;
    this.a = 0;
    this.eccSquared = 0;
    this.setEllipsoid();
  }

  public convertLatLngToUtm(
    latitude: number,
    longitude: number,
    precision: number
  ): {Easting: number; Northing: number; ZoneNumber: number; ZoneLetter: string} {
    if (this.status) {
      throw 'No ecclipsoid data associated with unknown datum: ' + this.datumName;
    }

    if (!Number.isInteger(precision)) {
      throw 'Precision is not integer number.';
    }

    // latitude = parseFloat(latitude);
    // longitude = parseFloat(longitude);

    const LongTemp = longitude;
    const LatRad = this.toRadians(latitude);
    const LongRad = this.toRadians(LongTemp);
    let ZoneNumber = 32;
    if (LongTemp >= 8 && LongTemp <= 13 && latitude > 54.5 && latitude < 58) {
      ZoneNumber = 32;
    } else if (latitude >= 56.0 && latitude < 64.0 && LongTemp >= 3.0 && LongTemp < 12.0) {
      ZoneNumber = 32;
    } else {
      ZoneNumber = Math.round((LongTemp + 180) / 6 + 1);

      if (latitude >= 72.0 && latitude < 84.0) {
        if (LongTemp >= 0.0 && LongTemp < 9.0) {
          ZoneNumber = 31;
        } else if (LongTemp >= 9.0 && LongTemp < 21.0) {
          ZoneNumber = 33;
        } else if (LongTemp >= 21.0 && LongTemp < 33.0) {
          ZoneNumber = 35;
        } else if (LongTemp >= 33.0 && LongTemp < 42.0) {
          ZoneNumber = 37;
        }
      }
    }

    const LongOrigin = (ZoneNumber - 1) * 6 - 180 + 3; // +3 puts origin in middle of zone
    const LongOriginRad = this.toRadians(LongOrigin);

    const UTMZone = this.getUtmLetterDesignator(latitude);

    const eccPrimeSquared = this.eccSquared / (1 - this.eccSquared);

    const N = this.a / Math.sqrt(1 - this.eccSquared * Math.sin(LatRad) * Math.sin(LatRad));
    const T = Math.tan(LatRad) * Math.tan(LatRad);
    const C = eccPrimeSquared * Math.cos(LatRad) * Math.cos(LatRad);
    const A = Math.cos(LatRad) * (LongRad - LongOriginRad);

    const M =
      this.a *
      ((1 - this.eccSquared / 4 - (3 * this.eccSquared * this.eccSquared) / 64 - (5 * this.eccSquared * this.eccSquared * this.eccSquared) / 256) *
        LatRad -
        ((3 * this.eccSquared) / 8 +
          (3 * this.eccSquared * this.eccSquared) / 32 +
          (45 * this.eccSquared * this.eccSquared * this.eccSquared) / 1024) *
          Math.sin(2 * LatRad) +
        ((15 * this.eccSquared * this.eccSquared) / 256 + (45 * this.eccSquared * this.eccSquared * this.eccSquared) / 1024) * Math.sin(4 * LatRad) -
        ((35 * this.eccSquared * this.eccSquared * this.eccSquared) / 3072) * Math.sin(6 * LatRad));

    let UTMEasting =
      0.9996 * N * (A + ((1 - T + C) * A * A * A) / 6 + ((5 - 18 * T + T * T + 72 * C - 58 * eccPrimeSquared) * A * A * A * A * A) / 120) + 500000.0;
    let UTMNorthing =
      0.9996 *
      (M +
        N *
          Math.tan(LatRad) *
          ((A * A) / 2 +
            ((5 - T + 9 * C + 4 * C * C) * A * A * A * A) / 24 +
            ((61 - 58 * T + T * T + 600 * C - 330 * eccPrimeSquared) * A * A * A * A * A * A) / 720));
    if (latitude < 0) {
      UTMNorthing += 10000000.0;
    }
    UTMNorthing = this.precisionRound(UTMNorthing, precision);
    UTMEasting = this.precisionRound(UTMEasting, precision);
    return {Easting: UTMEasting, Northing: UTMNorthing, ZoneNumber: ZoneNumber, ZoneLetter: UTMZone};
  }

  private getUtmLetterDesignator(latitude: number) {
    if (latitude <= 84 && latitude >= 72) {
      return 'X';
    } else if (latitude < 72 && latitude >= 64) {
      return 'W';
    } else if (latitude < 64 && latitude >= 56) {
      return 'V';
    } else if (latitude < 56 && latitude >= 48) {
      return 'U';
    } else if (latitude < 48 && latitude >= 40) {
      return 'T';
    } else if (latitude < 40 && latitude >= 32) {
      return 'S';
    } else if (latitude < 32 && latitude >= 24) {
      return 'R';
    } else if (latitude < 24 && latitude >= 16) {
      return 'Q';
    } else if (latitude < 16 && latitude >= 8) {
      return 'P';
    } else if (latitude < 8 && latitude >= 0) {
      return 'N';
    } else if (latitude < 0 && latitude >= -8) {
      return 'M';
    } else if (latitude < -8 && latitude >= -16) {
      return 'L';
    } else if (latitude < -16 && latitude >= -24) {
      return 'K';
    } else if (latitude < -24 && latitude >= -32) {
      return 'J';
    } else if (latitude < -32 && latitude >= -40) {
      return 'H';
    } else if (latitude < -40 && latitude >= -48) {
      return 'G';
    } else if (latitude < -48 && latitude >= -56) {
      return 'F';
    } else if (latitude < -56 && latitude >= -64) {
      return 'E';
    } else if (latitude < -64 && latitude >= -72) {
      return 'D';
    } else if (latitude < -72 && latitude >= -80) {
      return 'C';
    } else {
      return 'Z';
    }
  }

  public convertUtmToLatLng(UTMEasting: number, UTMNorthing: number, UTMZoneNumber: number, UTMZoneLetter: string): LatLngLiteral {
    const e1 = (1 - Math.sqrt(1 - this.eccSquared)) / (1 + Math.sqrt(1 - this.eccSquared));
    const x = UTMEasting - 500000.0; // remove 500,000 meter offset for longitude
    let y = UTMNorthing;
    const ZoneNumber = UTMZoneNumber;
    const ZoneLetter = UTMZoneLetter;
    if (UTMEasting === undefined) {
      throw 'Please pass the UTMEasting!';
    }
    if (UTMNorthing === undefined) {
      throw 'Please pass the UTMNorthing!';
    }
    if (UTMZoneNumber === undefined) {
      throw 'Please pass the UTMZoneNumber!';
    }
    if (UTMZoneLetter === undefined) {
      throw 'Please pass the UTMZoneLetter!';
    }

    if (['N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].indexOf(ZoneLetter) !== -1) {
      //should be empty
    } else {
      y -= 10000000.0;
    }

    const LongOrigin = (ZoneNumber - 1) * 6 - 180 + 3;

    const eccPrimeSquared = this.eccSquared / (1 - this.eccSquared);

    const M = y / 0.9996;
    const mu =
      M /
      (this.a *
        (1 - this.eccSquared / 4 - (3 * this.eccSquared * this.eccSquared) / 64 - (5 * this.eccSquared * this.eccSquared * this.eccSquared) / 256));

    const phi1Rad =
      mu +
      ((3 * e1) / 2 - (27 * e1 * e1 * e1) / 32) * Math.sin(2 * mu) +
      ((21 * e1 * e1) / 16 - (55 * e1 * e1 * e1 * e1) / 32) * Math.sin(4 * mu) +
      ((151 * e1 * e1 * e1) / 96) * Math.sin(6 * mu);
    // const phi1 = this.toDegrees(phi1Rad)

    const N1 = this.a / Math.sqrt(1 - this.eccSquared * Math.sin(phi1Rad) * Math.sin(phi1Rad));
    const T1 = Math.tan(phi1Rad) * Math.tan(phi1Rad);
    const C1 = eccPrimeSquared * Math.cos(phi1Rad) * Math.cos(phi1Rad);
    const R1 = (this.a * (1 - this.eccSquared)) / Math.pow(1 - this.eccSquared * Math.sin(phi1Rad) * Math.sin(phi1Rad), 1.5);
    const D = x / (N1 * 0.9996);

    let Lat =
      phi1Rad -
      ((N1 * Math.tan(phi1Rad)) / R1) *
        ((D * D) / 2 -
          ((5 + 3 * T1 + 10 * C1 - 4 * C1 * C1 - 9 * eccPrimeSquared) * D * D * D * D) / 24 +
          ((61 + 90 * T1 + 298 * C1 + 45 * T1 * T1 - 252 * eccPrimeSquared - 3 * C1 * C1) * D * D * D * D * D * D) / 720);
    Lat = this.toDegrees(Lat);

    let Long =
      (D -
        ((1 + 2 * T1 + C1) * D * D * D) / 6 +
        ((5 - 2 * C1 + 28 * T1 - 3 * C1 * C1 + 8 * eccPrimeSquared + 24 * T1 * T1) * D * D * D * D * D) / 120) /
      Math.cos(phi1Rad);
    Long = LongOrigin + this.toDegrees(Long);
    return {lat: Lat, lng: Long};
  }

  private toDegrees(rad: number) {
    return (rad / Math.PI) * 180;
  }

  private toRadians(deg: number) {
    return (deg * Math.PI) / 180;
  }

  private precisionRound(num: number, precision: number) {
    const factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
  }

  private setEllipsoid() {
    switch (this.datumName) {
      case 'Airy':
        this.a = 6377563;
        this.eccSquared = 0.00667054;
        break;
      case 'Australian National':
        this.a = 6378160;
        this.eccSquared = 0.006694542;
        break;
      case 'Bessel 1841':
        this.a = 6377397;
        this.eccSquared = 0.006674372;
        break;
      case 'Bessel 1841 Nambia':
        this.a = 6377484;
        this.eccSquared = 0.006674372;
        break;
      case 'Clarke 1866':
        this.a = 6378206;
        this.eccSquared = 0.006768658;
        break;
      case 'Clarke 1880':
        this.a = 6378249;
        this.eccSquared = 0.006803511;
        break;
      case 'Everest':
        this.a = 6377276;
        this.eccSquared = 0.006637847;
        break;
      case 'Fischer 1960 Mercury':
        this.a = 6378166;
        this.eccSquared = 0.006693422;
        break;
      case 'Fischer 1968':
        this.a = 6378150;
        this.eccSquared = 0.006693422;
        break;
      case 'GRS 1967':
        this.a = 6378160;
        this.eccSquared = 0.006694605;
        break;
      case 'GRS 1980':
        this.a = 6378137;
        this.eccSquared = 0.00669438;
        break;
      case 'Helmert 1906':
        this.a = 6378200;
        this.eccSquared = 0.006693422;
        break;
      case 'Hough':
        this.a = 6378270;
        this.eccSquared = 0.00672267;
        break;
      case 'International':
        this.a = 6378388;
        this.eccSquared = 0.00672267;
        break;
      case 'Krassovsky':
        this.a = 6378245;
        this.eccSquared = 0.006693422;
        break;
      case 'Modified Airy':
        this.a = 6377340;
        this.eccSquared = 0.00667054;
        break;
      case 'Modified Everest':
        this.a = 6377304;
        this.eccSquared = 0.006637847;
        break;
      case 'Modified Fischer 1960':
        this.a = 6378155;
        this.eccSquared = 0.006693422;
        break;
      case 'South American 1969':
        this.a = 6378160;
        this.eccSquared = 0.006694542;
        break;
      case 'WGS 60':
        this.a = 6378165;
        this.eccSquared = 0.006693422;
        break;
      case 'WGS 66':
        this.a = 6378145;
        this.eccSquared = 0.006694542;
        break;
      case 'WGS 72':
        this.a = 6378135;
        this.eccSquared = 0.006694318;
        break;
      case 'ED50':
        this.a = 6378388;
        this.eccSquared = 0.00672267;
        break; // International Ellipsoid
      case 'WGS 84':
      case 'EUREF89': // Max deviation from WGS 84 is 40 cm/km see http://ocq.dk/euref89 (in danish)
      case 'ETRS89': // Same as EUREF89
        this.a = 6378137;
        this.eccSquared = 0.00669438;
        break;
      default:
        this.status = true;
      //   new Error('No ecclipsoid data associated with unknown datum: '.name);
    }
  }
}

export default UTMLatLng;
