import React from 'react';

import {Stack, Typography} from '@mui/material';
import List from 'components/list/List';
import {DateField, LinkField, ListField} from 'components/list/Fields';
import CreateButton from 'components/forms/CreateButton';
import EventForm from 'components/Event/EventForm';

const MyEventsPage = () => {
  return (
    <React.Fragment>
      <Typography variant="h5">My Events</Typography>
      <List
        bulkOptions={false}
        toolbar={
          <Stack direction="row" alignItems="right" justifyContent="flex-end" gap={2}>
            <CreateButton label="Create New Event">
              <EventForm />
            </CreateButton>
          </Stack>
        }
      >
        <LinkField source="name" label="Event" link={(record) => `/my_events/${record?.slug}`} />
        <DateField source="startDate" label="Start Date" />
        <DateField source="endDate" label="End Date" />
        <ListField source="status" label="Status" />
      </List>
    </React.Fragment>
  );
};

export default MyEventsPage;
