import React, {ReactNode} from 'react';
import {Edit} from '@mui/icons-material';
import {Button} from '@mui/material';
import ButtonDialog from 'components/forms/ButtonDialog';
import {useResourceContext} from 'components/record/ResourceContext';
import EditDialogBody from './EditDialogBody';
import {useRecordContext} from 'components/record/useRecordContext';

const EditButton = (props: EditButtonProps) => {
  const {resource} = useResourceContext();
  const {refresh} = useRecordContext();
  const {children, label = `Edit`, ...rest} = props;

  return (
    <ButtonDialog
      buttonLabel={label}
      startIcon={<Edit />}
      dialogTitle={`Edit ${resource}`}
      primaryButton={
        <Button type="submit" form={`edit_${resource}`} variant="contained">
          Save
        </Button>
      }
      {...rest}
    >
      <EditDialogBody onSuccess={refresh}>{children}</EditDialogBody>
    </ButtonDialog>
  );
};

type EditButtonProps = {
  children: ReactNode;
  label?: string;
};

export default EditButton;
