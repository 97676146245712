import dayjs from 'dayjs';

const OmitAttributes: {[key: string]: Array<string>} = {
  'Event': ['competitors', 'entries', 'subEvents', 'settings'],
  'Entry': ['createdAt', 'userName'],
  'Map': ['createdAt'],
  'Route': ['createdAt'],
  'Competitor': ['device', 'user'],
  'Device': ['last', 'battery'],
  'Group': ['groupUsers']
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformDatesAndOmitRelated = (record: any, resource: string) => {
  const newRecord = {...record};
  if (!record) {
    return record;
  }
  for (const key of Object.keys(record)) {
    if ((key.includes('Date') || key.includes('Time')) && typeof record[key] === 'string') {
      newRecord[key] = dayjs(record[key]);
    }
    if (OmitAttributes[resource] && Array.isArray(OmitAttributes[resource]) && OmitAttributes[resource].includes(key)) {
      delete newRecord[key];
    }
  }
  return newRecord;
};
