import React from 'react';
import ActionMenuItem, {ActionMenuItemProps} from './ActionMenuItem';
import {useRecordContext} from 'components/record/useRecordContext';
import {RecordType} from 'components/record/RecordContext';
import {useMutate} from 'hooks/useFetchData';
import {useResourceContext} from 'components/record/ResourceContext';
import {toast} from 'react-toastify';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';

const ActionMenuItemOrder = (props: ActionMenuItemOrderProps) => {
  const {onSuccess, order, ...rest} = props;
  const {resource, params} = useResourceContext();
  const {id} = useRecordContext();

  const [orderMutate] = useMutate<{item: RecordType}>('order', resource, {
    variables: {
      id,
      ...params,
      order
    }
  });

  const handleClick = () => {
    orderMutate()
      .then((res) => {
        if (res?.data?.item) {
          toast.success(`${resource} order changed.`);
        }
      })
      .catch((err) => toast.error(err))
      .finally(() => {
        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      });
  };
  return <ActionMenuItem onClick={handleClick} {...rest} />;
};

export default ActionMenuItemOrder;

type ActionMenuItemOrderProps = ActionMenuItemProps & {
  order: string;
};

export const ActionMenuItemOrderUp = (props: ActionMenuItemProps) => <ActionMenuItemOrder label="Up" icon={<ArrowUpward />} order="up" {...props} />;
export const ActionMenuItemOrderDown = (props: ActionMenuItemProps) => (
  <ActionMenuItemOrder label="Down" icon={<ArrowDownward />} order="down" {...props} />
);
