import React from 'react';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import {useRecordContext} from 'components/record/useRecordContext';
import List from 'components/list/List';
import {Person} from '@mui/icons-material';
import {ListField} from 'components/list/Fields';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';
import GroupUsersForm from '../GroupUsersForm';
import CreateButton from 'components/forms/CreateButton';

const GroupPreview = () => {
  const {id, record: group} = useRecordContext();

  return (
    <ResourceContextProvider resource="GroupUser" params={{groupId: id}}>
      <List
        hidePagination
        title={group.name}
        avatar={<Person />}
        bulkOptions
        toolbar={
          <React.Fragment>
            <CreateButton many>
              <GroupUsersForm />
            </CreateButton>
          </React.Fragment>
        }
      >
        <ListField source="name" label="Name" />
        <ActionMenu label="Actions" align="right" width="5%">
          <ActionMenuItemDelete />
        </ActionMenu>
      </List>
    </ResourceContextProvider>
  );
};

export default GroupPreview;
