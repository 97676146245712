import React from 'react'
import { PulseLoader } from 'react-spinners'

const Loader = ({ loading }) => {
  return (
    <PulseLoader
      sizeunit={'px'}
      size={30}
      color={'#ff0066'}
      loading={loading}
    />
  )
}

export default Loader
