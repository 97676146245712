import React from 'react';
import ImageOverlayRotated from '../MyEvents/Track/ImageOverlayRotated';
import MapContainer from '../common/MapContainer';
import {SubEventType} from 'components/SubEvent/SubEvents';
import {useWatch} from 'react-hook-form';

const ViewMap = ({subEvent}: {subEvent: SubEventType}) => {
  const topLeft = useWatch({name: 'topLeft'});
  const topRight = useWatch({name: 'topRight'});
  const bottomLeft = useWatch({name: 'bottomLeft'});
  const url = useWatch({name: 'url'});

  return (
    <MapContainer center={subEvent.event.location} zoom={subEvent.event.zoom} style={{width: '100%', height: '650px', position: 'relative'}}>
      <ImageOverlayRotated topLeft={topLeft} topRight={topRight} bottomLeft={bottomLeft} opacity={0.5} image={process.env.REACT_APP_UPLOADS + url} />
    </MapContainer>
  );
};

export default ViewMap;
