import React from 'react';

import EventInfo from './EventInfo';
import CompetitorsSample from '../Competitor/CompetitorsSample';
import Entries from '../Entry/Entries';
import SubEvents from '../SubEvent/SubEvents';
import {Grid, Stack} from '@mui/material';
import EventForm from './EventForm';
import EditButton from 'components/forms/EditButton';
import {useNavigate, useParams} from 'react-router';
import DeleteButton from 'components/forms/DeleteButton';
import ShowTabs from 'components/tabs/ShowTabs';
import {useRecordContext} from 'components/record/useRecordContext';
import TabElement from 'components/tabs/TabElement';
import EventSettings from './EventSettings';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import ResourceRecordContextProvider from 'components/record/ResourceRecordProvider';

const EventPage = () => {
  const navigate = useNavigate();
  const {record: event} = useRecordContext();
  const {tab} = useParams();

  const onSuccess = () => {
    navigate('/my_events');
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="right" justifyContent="space-between" gap={2}>
          <h1>{event.name}</h1>
          <Stack direction="row" alignItems="right" justifyContent="flex-end" gap={2} mb={5}>
            <EditButton>
              <EventForm />
            </EditButton>
            <DeleteButton onSuccess={onSuccess}>Are you sure you want to delete this event?</DeleteButton>
          </Stack>
        </Stack>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <ShowTabs baseUrl={`/my_events/${event.slug}`} value={tab}>
            <TabElement label="Event" value="0">
              <EventInfo />
              <SubEvents />
              <Entries />
              <CompetitorsSample />
            </TabElement>
            <TabElement label="Settings" value="1">
              <ResourceContextProvider resource="EventSettings">
                <ResourceRecordContextProvider id={event.slug}>
                  <EventSettings />
                </ResourceRecordContextProvider>
              </ResourceContextProvider>
            </TabElement>
          </ShowTabs>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventPage;
