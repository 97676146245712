import React from 'react'

const InputFile = ({ onFileSelect, className, label }) => {
  let t = {}
  return (
    <div>
      <input
        hidden
        type='file'
        ref={f => (t.fileInput = f)}
        onChange={onFileSelect}
      />
      <button className={className} onClick={(e) => {
        e.preventDefault()
        t.fileInput.click()
      }}>{label}</button>
    </div>
  )
}

export default InputFile
