import React from 'react';
import {Link, useParams} from 'react-router-dom';

import Controls from '../Control/Controls';
import Maps from '../Map/Maps';
import Competitors from '../Competitor/Competitors';
import {useRecordContext} from 'components/record/useRecordContext';
import {Button, Grid, Stack} from '@mui/material';
import Routes from 'components/Map/Routes';
import ShowTabs from 'components/tabs/ShowTabs';
import TabElement from 'components/tabs/TabElement';

const SubEventPage = () => {
  const {record: subEvent} = useRecordContext();
  const {tab} = useParams();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="right" justifyContent="space-between" gap={2}>
          <h1>{subEvent.name}</h1>
          <Button component={Link} to={`/my_events/${subEvent.event.slug}`}>
            Back
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <ShowTabs baseUrl={`/sub_events/${subEvent.id}`} value={tab}>
          <TabElement label="Competitors" value="0">
            <Competitors />
          </TabElement>
          <TabElement label="Maps and Routes" value="1">
            <Maps />
            <Routes />
          </TabElement>
          <TabElement label="Controls" value="2">
            <Controls />
          </TabElement>
        </ShowTabs>
      </Grid>
    </Grid>
  );
};

export default SubEventPage;
