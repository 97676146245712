import {Identifier} from 'components/record/RecordContext';
import React from 'react';
import {ListFiltersType} from './List';

export const ListContext = React.createContext<ListContextValue>({
  refetch: () => {},
  addFilters: () => {},
  filters: {},
  selected: [],
  setSelected: () => {}
});

export type ListContextValue = {
  refetch: () => void;
  filters: ListFiltersType;
  addFilters: (filters: ListFiltersType) => void;
  selected: Identifier[];
  setSelected: React.Dispatch<React.SetStateAction<Identifier[]>>;
};

export const ListContextProvider = ({children, refetch, filters, addFilters}: ListContextProviderProps) => {
  const [selected, setSelected] = React.useState<Identifier[]>([]);
  return <ListContext.Provider value={{refetch, selected, setSelected, filters, addFilters}}>{children}</ListContext.Provider>;
};

export interface ListContextProviderProps {
  children: React.ReactNode;
  refetch: () => void;
  filters: ListFiltersType;
  addFilters: (filters: ListFiltersType) => void;
}

export const useListContext = (): ListContextValue => {
  return React.useContext<ListContextValue>(ListContext);
};
