import React from 'react';

import {FormControl} from '@mui/material';
import {TextInput, SwitchInput} from 'components/forms/Inputs';
import ColorInput from 'components/common/color-picker/ColorInput';
import {useWatch} from 'react-hook-form';
import MapContainer from 'components/common/MapContainer';
import {Polyline} from 'react-leaflet';
import TrackInput from 'components/common/TrackInput';
import {SubEventType} from 'components/SubEvent/SubEvents';

const RouteForm = ({subEvent}: {subEvent: SubEventType}) => {
  const event = subEvent.event;

  const color: string = useWatch({name: 'color'});
  const track = useWatch({name: 'track'});

  return (
    <React.Fragment>
      <TextInput required fullWidth label="Name" name="name" />
      <ColorInput required fullWidth name="color" label="Color" />
      <SwitchInput name="show" label="Visible" />
      <TrackInput name="track">
        {track && track.length > 0 && (
          <FormControl fullWidth>
            <MapContainer center={event.location} zoom={event.zoom} style={{width: '100%', height: '400px', position: 'relative'}}>
              <Polyline pathOptions={{color}} positions={track} />
            </MapContainer>
          </FormControl>
        )}
      </TrackInput>
    </React.Fragment>
  );
};

export default RouteForm;
