import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router';

const ShowTabs = (props: ShowTabsProps) => {
  const {baseUrl, value: initialValue, children} = props;

  const [value, setValue] = React.useState<string>(initialValue || '0');
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`${baseUrl}${newValue !== '0' ? '/' + newValue : ''}`);
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          {React.Children.map(children, (field) => {
            const element = field as React.ReactElement;
            const value: string = element.props.value;
            const label: string = element.props.label;
            return <Tab key={value} label={label} value={value} />;
          })}
        </TabList>
      </Box>
      {React.Children.map(children, (field) => {
        const element = field as React.ReactElement;
        const value: string = element.props.value;
        return (
          <TabPanel key={value} value={value} sx={{padding: '16px 0 0'}}>
            <element.type {...element.props} />
          </TabPanel>
        );
      })}
    </TabContext>
  );
};

export default ShowTabs;

type ShowTabsProps = {
  baseUrl: string;
  value?: string;
  children: Array<React.ReactElement> | React.ReactElement;
};
