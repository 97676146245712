import React from 'react';
import {AutocompleteInput} from 'components/forms/Inputs';
import {useController, useWatch} from 'react-hook-form';
import {useFetch} from 'hooks/useFetchData';
import List from 'components/list/List';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import {ListField} from 'components/list/Fields';

type GroupType = {id: number; name: string};

const CompetitorUserGroupForm = () => {
  const {data, loading} = useFetch<{items: GroupType[]}>('GET_LIST', 'Group');
  const groupsOptions = React.useMemo(() => (data?.items || []).map((item) => ({id: item.id, label: item.name})), [data]);

  const groupId = useWatch({name: 'groupId'});

  const {
    field: {onChange: onChangeDeviceId}
  } = useController({name: 'userIds'});

  return (
    <React.Fragment>
      <AutocompleteInput fullWidth loading={loading} required name="groupId" label="User Group" options={groupsOptions} />
      {groupId && (
        <ResourceContextProvider resource="User">
          <List filters={{groupIds: [groupId]}} hidePagination bulkOptions onChangeSelected={onChangeDeviceId}>
            <ListField source="firstName" label="First Name" />
            <ListField source="lastName" label="Last Name" />
          </List>
        </ResourceContextProvider>
      )}
    </React.Fragment>
  );
};

export default CompetitorUserGroupForm;
