import React, {FormEventHandler} from 'react';
import {useNavigate} from 'react-router-dom';
import {gql, useMutation} from '@apollo/client';

import LocalStorageService from '../../services/LocalStorageService';
import {Avatar, Box, CssBaseline, Grid, Paper, TextField, Typography, Button} from '@mui/material';
import {LockOutlined} from '@mui/icons-material';

const LOGIN_QUERY = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access_token
      refresh_token
      user {
        name
        role {
          name
        }
      }
    }
  }
`;

const LoginPage = () => {
  // const [email, setEmail] = useState<string>('');
  // const [password, setPassword] = useState<string>('');
  // const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const [login] = useMutation(LOGIN_QUERY, {
    onCompleted: (data) => {
      LocalStorageService.setTokens(data.login);
      navigate('/');
    },
    onError: (error) => {
      if (error.message) {
        console.log(error.message);
      } else {
        console.log('Network error.');
      }
    }
  });

  const onSubmitHandler: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = {
      email: data.get('email'),
      password: data.get('password')
    };
    login({
      variables: payload
    });
  };

  return (
    <Grid container component="main" sx={{height: '100vh', p: 2, alignItems: 'center', justifyContent: 'center'}}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate onSubmit={onSubmitHandler} sx={{mt: 1}}>
            <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}} size="medium">
              LogIn
            </Button>
            {/* <Copyright sx={{mt: 5}} /> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
