import React from 'react';
import {useController} from 'react-hook-form';

import xmldom from 'xmldom';
import togeojson from '@mapbox/togeojson';
import {toast} from 'react-toastify';
import FilePicker from './FilePicker';
import {FormControl} from '@mui/material';
import {LatLngLiteral} from 'leaflet';
import RangeInput from './RangeInput';

const TrackInput = (props: TrackInputProps) => {
  const {name, children} = props;

  const {
    field: {onChange: setTrack}
  } = useController({name});

  const [isInputSlider, setIsInputSlider] = React.useState<boolean>(false);
  const [data, setData] = React.useState<(LatLngLiteral & {time?: number})[]>([]);
  const [value, setValue] = React.useState<number[]>([]);
  const [minValue, setMinValue] = React.useState<number>(0);
  const [maxValue, setMaxValue] = React.useState<number>(0);

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0] as File;
      const extension = file.name.split('.')?.pop()?.toLowerCase();
      if (extension === 'gpx') {
        const reader = new window.FileReader();
        reader.onload = (e) => {
          const gpx = new xmldom.DOMParser().parseFromString(e.target?.result as string);
          const c = togeojson.gpx(gpx);
          const geojson = c.features[0];
          const size = geojson.geometry.coordinates.length;
          const data: (LatLngLiteral & {time?: number})[] = [];
          let flag = true;
          for (let i = 0; i < size; i++) {
            const obj: LatLngLiteral & {time?: number} = {
              lng: geojson.geometry.coordinates[i][0],
              lat: geojson.geometry.coordinates[i][1]
            };
            if (geojson.properties.coordTimes && geojson.properties.coordTimes.length > 0 && geojson.properties.coordTimes[i]) {
              const time = new Date(geojson.properties.coordTimes[i]).getTime();
              Object.assign(obj, {time});
            } else {
              flag = false;
            }
            data.push(obj);
          }
          const track: LatLngLiteral[] = [];
          data.forEach((d) =>
            track.push({
              lng: d.lng,
              lat: d.lat
            })
          );
          setData(data);
          setTrack(track);
          if (flag && data?.length > 0) {
            const min: number = data[0].time as number | 0;
            const max: number = data[data.length - 1].time as number | 0;
            setMaxValue(max);
            setMinValue(min);
            setValue([min, max]);
          }
          setIsInputSlider(flag);
        };
        reader.readAsText(file);
      } else {
        toast.error('Тhe file is not in correct format!');
      }
    }
  };

  const onChangeValue = (value: number[]) => {
    const track = [...data]
      .filter((point) => point.time && value[0] <= point.time && value[1] >= point.time)
      .map((point) => ({lng: point.lng, lat: point.lat}));

    setValue(value);
    setTrack(track);
  };

  return (
    <React.Fragment>
      <FormControl fullWidth margin="normal">
        <FilePicker onFileChange={handleFileChange} accept=".gpx" label="Upload Route" />
      </FormControl>
      {children}
      {isInputSlider && (
        <FormControl fullWidth margin="dense">
          <RangeInput min={minValue} max={maxValue} onChange={onChangeValue} value={value} />
        </FormControl>
      )}
    </React.Fragment>
  );
};

export default TrackInput;

type TrackInputProps = {
  children?: React.ReactNode;
  name: string;
};
