import React from 'react';

import {Grid} from '@mui/material';
import {SelectInput, SwitchInput, TextInput} from 'components/forms/Inputs';
import {useWatch} from 'react-hook-form';

const delayOptions = [10, 20, 30, 40, 50, 60, 120, 180, 240, 300].map((x) => ({id: x, label: x}));
// const gpsIntervalOptions = [1, 2, 3, 5, 10].map((x) => ({id: x, label: x}));

const EventSettingsForm = () => {
  const useResults = useWatch({name: 'useResults'});

  return (
    <Grid container>
      <Grid item xs={12} md={6} padding={2}>
        <SwitchInput sx={{width: '100%'}} name="canEnter" label="Can competitors enter for events" />
        <SwitchInput sx={{width: '100%'}} name="autoAcceptEntry" label="Auto accept entries for events" />
        <SwitchInput sx={{width: '100%'}} name="analytic" label="Analytic" />
        <SwitchInput sx={{width: '100%'}} name="useResults" label="Use results" />
        {useResults && <TextInput fullWidth type="number" name="liveResultsCompetitionId" label="Live Results CompetitionId" />}
        <SelectInput fullWidth required name="delay" label="Delay" options={delayOptions} />
        {/* <SelectInput fullWidth required name="gpsInterval" label="GPS Interval" options={gpsIntervalOptions} /> */}
      </Grid>
    </Grid>
  );
};

export default EventSettingsForm;
