import React from 'react';
import {SubEventType} from 'components/SubEvent/SubEvents';
import {useController} from 'react-hook-form';
import RangeInput from 'components/common/RangeInput';
import {CircularProgress, FormControl} from '@mui/material';
import {TrackPoint} from './SingleTrack';
import ShowTrack from './ShowTrack';

const EditTrackForm = (props: EditTrackFormProps) => {
  const {color, subEvent, data} = props;

  const {
    field: {value: track, onChange: setTrack}
  } = useController({name: 'track'});

  const [minValue, setMinValue] = React.useState<number>(0);
  const [maxValue, setMaxValue] = React.useState<number>(0);
  const [value, setValue] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (data && data.length > 0) {
      setTrack(JSON.parse(JSON.stringify(data)));
      setMinValue(data[0].time);
      setMaxValue(data[data.length - 1].time);
      setValue([data[0].time, data[data.length - 1].time]);
    }
  }, [data]);

  const onChangeValue = (newValue: number[]) => {
    const size = data.length;
    const newTrack = [];
    for (let i = 0; i < size; i++) {
      if (newValue[0] <= data[i].time && newValue[1] >= data[i].time) {
        newTrack.push(data[i]);
      }
    }
    setValue(newValue);
    setTrack(newTrack);
  };

  if (value.length < 2) {
    return <CircularProgress />;
  }

  return (
    <React.Fragment>
      <ShowTrack subEvent={subEvent} color={color} track={track} />
      <FormControl fullWidth margin="dense">
        <RangeInput min={minValue} max={maxValue} onChange={onChangeValue} value={value} />
      </FormControl>
    </React.Fragment>
  );
};

export default EditTrackForm;

type EditTrackFormProps = {
  subEvent: SubEventType;
  color: string;
  data: TrackPoint[];
};
