import React from 'react';
import {Card, CardContent, CardHeader, IconButton, Typography} from '@mui/material';
import {Sync} from '@mui/icons-material';

const CardFrame = (props: CardFrameProps) => {
  const {
    avatar,
    title,
    children,
    refresh,
    action = refresh && (
      <IconButton onClick={refresh} color="primary">
        <Sync />
      </IconButton>
    )
  } = props;

  return (
    <Card>
      <CardHeader
        avatar={avatar}
        title={
          <Typography component="div" variant="h5">
            {title}
          </Typography>
        }
        action={action}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardFrame;

type CardFrameProps = {
  avatar?: React.ReactNode;
  title: React.ReactNode;
  children?: React.ReactNode;
  refresh?: () => void;
  action?: React.ReactNode;
};
