/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import {xml2js} from 'xml-js';

import FilePickerDialog from 'components/forms/FilePickerDialog';
import {Button} from '@mui/material';
import {FormContainer} from 'react-hook-form-mui';
import {toast} from 'react-toastify';
import {useRecordContext} from 'components/record/useRecordContext';

const IMPORT_SUBEVENTS = gql`
  mutation ImportSubEvents($slug: String!, $payload: [ImportSubEventPayload!]) {
    importSubEvents(slug: $slug, payload: $payload) {
      id
    }
  }
`;

const ImportSubEventsButton = () => {
  const {record: event} = useRecordContext();

  const [subEvents, setSubEvents] = useState<{name: string}[]>([]);

  const [importSubEvents] = useMutation(IMPORT_SUBEVENTS, {
    onCompleted: (data) => {
      if (data.importSubEvents) {
        console.log('Done');
      }
    }
  });

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>, callback: () => void) => {
    const reader = new window.FileReader();
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const extension = fileName.split('.').pop()?.toLowerCase();
      if (extension === 'xml') {
        reader.onload = (e) => {
          handleFileChange(e.target?.result as string);
          callback();
        };
        reader.readAsText(file);
      } else {
        toast.error('Тhe file is not in correct format!');
      }
    }
  };

  const handleFileChange = (xmlString: string) => {
    const data: any = xml2js(xmlString, {compact: true});
    if (data.CourseData && data.CourseData.RaceCourseData && data.CourseData.RaceCourseData) {
      const allControls = data.CourseData.RaceCourseData.Control.map((control: any) => {
        return {
          name: control.Id._text,
          type: 'default',
          position: control.Position._attributes
        };
      });
      allControls.map((control: any) => {
        control.position = {
          lat: parseFloat(control.position.lat),
          lng: parseFloat(control.position.lng)
        };
        control.id = parseInt(control.name) || 0;
        control.siCode = parseInt(control.name) || 0;
      });
      const subEventsObj = data.CourseData.RaceCourseData.Course.map((course: any) => {
        const controls = course.CourseControl.map((control: any) => {
          const id = control.Control._text;
          return allControls.find((c: any) => c.name === id);
        });
        return {
          name: course.Name._text,
          controls
        };
      });
      setSubEvents(subEventsObj);
    }
  };

  const handleSubmit = () => {
    importSubEvents({
      variables: {
        slug: event.slug,
        payload: subEvents
      }
    });
  };

  return (
    <React.Fragment>
      <FilePickerDialog
        label="Import XML (IOF v3)"
        accept=".xml"
        onFileChange={onFileChange}
        fullWidth={false}
        primaryButton={
          <Button type="submit" form="create_CompetitorTrack" variant="contained">
            Create
          </Button>
        }
      >
        <FormContainer onSuccess={handleSubmit}>
          {subEvents.map((subEvent) => (
            <div key={subEvent.name}>{subEvent.name}</div>
          ))}
        </FormContainer>
      </FilePickerDialog>
    </React.Fragment>
  );
};

export default ImportSubEventsButton;
