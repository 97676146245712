import React from 'react';
import {Link} from 'react-router-dom';
import {ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {Dashboard as DashboardIcon, NearMe as NearMeIcon, People as PeopleIcon, Event as EventIcon} from '@mui/icons-material';

export const MainListItems = (
  <React.Fragment>
    <ListItemButton component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton component={Link} to="/my_events">
      <ListItemIcon>
        <EventIcon />
      </ListItemIcon>
      <ListItemText primary="Events" />
    </ListItemButton>
    <ListItemButton component={Link} to="/my_devices">
      <ListItemIcon>
        <NearMeIcon />
      </ListItemIcon>
      <ListItemText primary="Devices" />
    </ListItemButton>
    <ListItemButton component={Link} to="/users">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItemButton>
  </React.Fragment>
);

export const AdminListItems = (
  <React.Fragment>
    <ListItemButton component={Link} to="/my_events">
      <ListItemIcon>
        <EventIcon />
      </ListItemIcon>
      <ListItemText primary="All Events" />
    </ListItemButton>
    <ListItemButton component={Link} to="/all_devices">
      <ListItemIcon>
        <NearMeIcon />
      </ListItemIcon>
      <ListItemText primary="All Devices" />
    </ListItemButton>
    <ListItemButton component={Link} to="/all_users">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="All Users" />
    </ListItemButton>
  </React.Fragment>
);
