import React from 'react';
import SubEventMapContainer from './SubEventMapContainer';
import MapEventHandlers from 'components/common/MapEventHandlers';
import {useController, useWatch} from 'react-hook-form';
import DraggableMarkers from 'components/common/DraggableMarker';
import {FormControl} from '@mui/material';
import {LatLngLiteral, LeafletMouseEventHandlerFn} from 'leaflet';
import {SubEventType} from 'components/SubEvent/SubEvents';
import icons, {IconType} from './Icons/MarkerIcons';

const ControlPositionInput = (props: ControlPositionInput) => {
  const {subEvent, multi = false} = props;

  const {
    field: {value: marker, onChange: onChangeMarker}
  } = useController({name: multi ? 'positions' : 'position'});

  const type = useWatch({name: 'type'});

  const handleMapClick: LeafletMouseEventHandlerFn = (e) => {
    if (multi) {
      onChangeMarker([...(marker || []), e.latlng]);
    } else {
      if (!marker) {
        onChangeMarker(e.latlng);
      }
    }
  };

  const handleChangeMarker = (index: number) => (latlng: LatLngLiteral) => {
    marker[index] = latlng;
    onChangeMarker(marker);
  };

  return (
    <FormControl fullWidth>
      <SubEventMapContainer className="leaflet-crosshair" subEvent={subEvent} style={{width: '100%', height: '500px', position: 'relative'}}>
        <MapEventHandlers click={handleMapClick} />
        {!multi && marker && <DraggableMarkers position={marker} onUpdate={onChangeMarker} icon={icons[(type as IconType) || 'default']} />}
        {multi &&
          marker &&
          marker.map((marker: LatLngLiteral, index: number) => (
            <DraggableMarkers key={index} position={marker} onUpdate={handleChangeMarker(index)} icon={icons[(type as IconType) || 'default']} />
          ))}
      </SubEventMapContainer>
    </FormControl>
    // <div className="form-group has-feedback">
    //   <label className="form-control-label" htmlFor={name}>
    //     {label}
    //   </label>
    //   <MapContainer
    //     center={subEvent.event.location}
    //     zoom={subEvent.event.zoom}
    //     className="leaflet-crosshair"
    //     style={{
    //       width: '100%',
    //       height: '500px',
    //       position: 'relative',
    //       zIndex: '0'
    //     }}
    //   >
    //     {subEvent.routes &&
    //       subEvent.routes.map((route) => <Polyline key={route.id} color={route.color} positions={route.track} options={{stroke: false}} />)}
    //     {subEvent.maps &&
    //       subEvent.maps.map((m) => (
    //         <ImageOverlayRotated
    //           key={m.id}
    //           topLeft={m.topLeft}
    //           topRight={m.topRight}
    //           bottomLeft={m.bottomLeft}
    //           opacity={1}
    //           image={`${process.env.REACT_APP_UPLOADS}/${m.url}`}
    //         />
    //       ))}
    //     {children}
    //   </MapContainer>
    //   {error && (
    //     <div className="invalid-feedback" style={{display: 'block'}}>
    //       {error}
    //     </div>
    //   )}
    // </div>
  );
};

export default ControlPositionInput;

type ControlPositionInput = {
  subEvent: SubEventType;
  multi?: boolean;
};
