import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useApolloClient} from '@apollo/client';

import LocalStorageService from '../../services/LocalStorageService';

const Logout = () => {
  const navigate = useNavigate();
  const client = useApolloClient();

  React.useEffect(() => {
    LocalStorageService.clearTokens();
    resetApolloClient();
  }, []);

  const resetApolloClient = async () => {
    await client.resetStore();
    navigate('/login');
  };

  return <></>;
};

export default Logout;
