import React, {useMemo} from 'react';
import {TableCell} from '@mui/material';
import {useRecordContext} from 'components/record/useRecordContext';
import {Link} from 'react-router-dom';
import {RecordType} from 'components/record/RecordContext';
import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAttributeFromObject = (object: any, attributes: string) => {
  const attributesList = attributes.split('.');
  let obj = object;
  for (const attr of attributesList) {
    if (!obj[attr]) {
      return '';
    }
    obj = obj[attr];
  }
  return obj;
};

export const ListField = (props: FieldProps) => {
  const {source, transform} = props;
  const {record} = useRecordContext();
  const formatted = useMemo(() => {
    if (typeof transform === 'function') {
      return transform(getAttributeFromObject(record, source));
    }
    return getAttributeFromObject(record, source);
  }, [record, transform, source]);
  return <TableCell>{formatted}</TableCell>;
};

export type FieldProps = {
  transform?: (field: string) => string;
  source: string;
  label?: string;
  width?: string;
  children?: JSX.Element | JSX.Element[];
};

export const BooleanField = (props: FieldProps) => {
  const {source, children} = props;
  const {record} = useRecordContext();
  if (getAttributeFromObject(record, source)) {
    return <TableCell>{children}</TableCell>;
  }
  return <TableCell></TableCell>;
};

export const LinkField = (props: LinkFieldProps) => {
  const {source, link} = props;
  const {record} = useRecordContext();

  const linkTo = useMemo(() => {
    if (typeof link === 'function') {
      return link(record);
    }
    return link;
  }, [record, link]);

  return (
    <TableCell>
      <Link to={linkTo}>{getAttributeFromObject(record, source)}</Link>
    </TableCell>
  );
};

type LinkFieldProps = FieldProps & {
  link: string | ((record?: RecordType) => string);
};

export const DateField = (props: DateFieldProps) => {
  const {record} = useRecordContext();
  const {format = 'YYYY-MM-DD HH:mm', source} = props;
  return <TableCell>{dayjs(getAttributeFromObject(record, source)).format(format)}</TableCell>;
};

type DateFieldProps = FieldProps & {
  format?: string;
};

export const ColorField = (props: FieldProps) => {
  const {source} = props;
  const {record} = useRecordContext();
  const color = getAttributeFromObject(record, source);
  return (
    <TableCell>
      <div style={{backgroundColor: color}}>{color}</div>
    </TableCell>
  );
};
