import {TextInput} from 'components/forms/Inputs';
import React from 'react';

const GroupForm = () => {
  return (
    <React.Fragment>
      <TextInput required fullWidth label="Name" name="name" autoFocus />
    </React.Fragment>
  );
};

export default GroupForm;
