import React from 'react';
import {ImageOverlay} from 'react-leaflet';
import Leaflet, {LatLngBoundsLiteral} from 'leaflet';
import {xml2js} from 'xml-js';

import ImageOverlayRotated from '../MyEvents/Track/ImageOverlayRotated';
import {plus, rotate, toVector} from '../common/TransformVectors';
import MapContainer from '../common/MapContainer';
import {SubEventType} from 'components/SubEvent/SubEvents';
import {useController, useWatch} from 'react-hook-form';
import FilePicker from 'components/common/FilePicker';
import {toast} from 'react-toastify';
import {FormControl} from '@mui/material';

const KmlMap = ({imageSrcData, subEvent}: {imageSrcData?: string; subEvent: SubEventType}) => {
  const height = useWatch({name: 'height'});
  const width = useWatch({name: 'width'});
  const event = subEvent.event;

  const {
    field: {value: topLeft, onChange: setTopLeft}
  } = useController({name: 'topLeft'});

  const {
    field: {value: topRight, onChange: setTopRight}
  } = useController({name: 'topRight'});

  const {
    field: {value: bottomLeft, onChange: setBottomLeft}
  } = useController({name: 'bottomLeft'});

  const mapBounds: LatLngBoundsLiteral = React.useMemo(
    () => [
      [0, 0],
      [height, width]
    ],
    [height, width]
  );

  const [isSetKml, setIsSetKml] = React.useState<boolean>(false);

  const handleKmlFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new window.FileReader();
      const file = e.target.files[0];
      const fileName = file.name || '';
      const extension = fileName.split('.').pop()?.toLowerCase() || ' ';
      if (extension !== 'kml') {
        toast.error('Wrong file type');
      } else {
        reader.onload = (e) => {
          try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data = xml2js(e.target?.result as string, {compact: true}) as unknown as any;
            const LatLonBox = data.kml.Folder.GroundOverlay.LatLonBox;
            const east = parseFloat(LatLonBox.east['_text']);
            const north = parseFloat(LatLonBox.north['_text']);
            const south = parseFloat(LatLonBox.south['_text']);
            const west = parseFloat(LatLonBox.west['_text']);
            const rotation = parseFloat(LatLonBox.rotation['_text']) * 0.017453292519943;
            const center = {
              lat: (north + south) / 2,
              lng: (east + west) / 2
            };
            const topLeft = plus(center, rotate(toVector(center, {lat: north, lng: west}), rotation));
            const topRight = plus(center, rotate(toVector(center, {lat: north, lng: east}), rotation));
            const bottomLeft = plus(center, rotate(toVector(center, {lat: south, lng: west}), rotation));
            setIsSetKml(true);

            setTopLeft(topLeft);
            setTopRight(topRight);
            setBottomLeft(bottomLeft);
          } catch (err) {
            console.log(err);
          }
        };
        reader.readAsText(file);
      }
    }
  };

  if (!imageSrcData) {
    return null;
  }

  return (
    <React.Fragment>
      {!isSetKml ? (
        <React.Fragment>
          <MapContainer
            className="leaflet-crosshair"
            bounds={mapBounds}
            style={{width: '100%', height: '650px', position: 'relative'}}
            crs={Leaflet.CRS.Simple}
            minZoom={-3}
            noTileLayer
          >
            <ImageOverlay url={imageSrcData} bounds={mapBounds} />
          </MapContainer>
          <FormControl fullWidth margin="normal">
            <FilePicker label="Select kml file" accept=".kml" onFileChange={handleKmlFile} />
          </FormControl>
        </React.Fragment>
      ) : (
        <MapContainer
          center={event.location}
          zoom={event.zoom}
          className="leaflet-crosshair"
          style={{width: '100%', height: '650px', position: 'relative'}}
        >
          <ImageOverlayRotated topLeft={topLeft} topRight={topRight} bottomLeft={bottomLeft} opacity={0.5} image={imageSrcData} />
        </MapContainer>
      )}
    </React.Fragment>
  );
};

export default KmlMap;
