import React from 'react';
import {gql, useLazyQuery} from '@apollo/client';

import {SubEventType} from 'components/SubEvent/SubEvents';
import EditTrackForm from './EditTrackForm';
import {TrackPoint} from './SingleTrack';
import {Button} from '@mui/material';
import CreateDialogBody from 'components/forms/CreateDialogBody';
import ButtonDialog from 'components/forms/ButtonDialog';

const GET_LIVE_DEVICES = gql`
  query GetLiveDevices($names: [String!]!, $slug: String, $from: Date) {
    getLiveDevices(names: $names, slug: $slug, from: $from) {
      name
      points
    }
  }
`;

const ImportTrack = (props: ImportTrackProps) => {
  const {
    subEvent,
    callback,
    competitor: {color, device}
  } = props;

  const [track, setTrack] = React.useState<TrackPoint[]>([]);

  const [getLiveDevicesData] = useLazyQuery(GET_LIVE_DEVICES);

  const onButtonClick = (callback: () => void) => {
    getLiveDevicesData({
      variables: {
        from: new Date(subEvent.event.startDate || '').getTime(),
        end: new Date(subEvent.event.endDate || '').getTime(),
        slug: subEvent.event.slug,
        names: [device.name]
      },
      onCompleted: ({getLiveDevices}) => {
        const startTime = new Date(subEvent.event.startDate || '').getTime();
        const data: TrackPoint[] = getLiveDevices[0].points.map((p: number[]) => {
          return {
            lng: p[2],
            lat: p[1],
            time: p[0] * 1000 + startTime
          };
        });
        data.sort((a, b) => a.time - b.time);
        setTrack(data);
        callback();
      }
    });
  };

  return (
    <ButtonDialog
      buttonLabel="Get Track from GPS"
      onClick={onButtonClick}
      primaryButton={
        <Button type="submit" form="create_CompetitorTrack" variant="contained">
          Create
        </Button>
      }
    >
      <CreateDialogBody onSuccess={callback}>
        <EditTrackForm subEvent={subEvent} data={track} color={color} />
      </CreateDialogBody>
    </ButtonDialog>
  );
};

export default ImportTrack;

type ImportTrackProps = {
  subEvent: SubEventType;
  competitor: {
    color: string;
    device: {
      name: string;
    };
  };
  callback?: () => void;
};
