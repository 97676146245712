import {Close} from '@mui/icons-material';
import {Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useDialogContext} from 'components/dialog/DialogContext';
import React, {ReactNode, useEffect} from 'react';

const CustomDialog = (props: CustomDialogProps) => {
  const {isOpen, open: openDialog, close: closeDialog} = useDialogContext();
  const {
    children,
    open,
    resetDialog,
    dialogTitle,
    maxWidth = 'md',
    primaryButton = (
      <Button variant="contained" onClick={closeDialog}>
        OK
      </Button>
    ),
    secondaryButton = <Button onClick={closeDialog}>Cancel</Button>
  } = props;

  useEffect(() => {
    if (!isOpen) {
      resetDialog();
    }
  }, [isOpen]);

  useEffect(() => {
    if (open) {
      openDialog();
    }
  }, [open]);

  return (
    <Dialog fullWidth={true} maxWidth={maxWidth} open={isOpen} onClose={closeDialog}>
      <DialogTitle>
        {dialogTitle}
        <Button sx={{position: 'absolute', top: '5px', right: '5px'}} onClick={closeDialog}>
          <Close />
        </Button>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {primaryButton}
        {secondaryButton}
      </DialogActions>
    </Dialog>
  );
};

type CustomDialogProps = {
  children: ReactNode;
  open: boolean;
  resetDialog: () => void;
  dialogTitle?: React.ReactNode;
  primaryButton?: JSX.Element;
  secondaryButton?: JSX.Element;
  maxWidth?: false | Breakpoint;
};

export default CustomDialog;
