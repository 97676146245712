import React from 'react';

import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material';
import {TextInput} from 'components/forms/Inputs';
import {useForm} from 'react-hook-form-mui';
import ImageFileUpload from 'components/Map/Image/ImageFileUpload';
import {ErrorMessage} from '@hookform/error-message';
import GeoReferenceMap from '../GeoreferenceMap';
import {SubEventType} from 'components/SubEvent/SubEvents';
import JpwMap from '../JgwMap';
import KmlMap from '../KmlMap';

const mapOptions = [
  {
    label: 'Import Image',
    id: 'option1'
  },
  {
    label: 'Import Map with jgw',
    id: 'option2'
  },
  {
    label: 'Import Map with kml',
    id: 'option3'
  }
];

const MapForm = ({subEvent}: {subEvent: SubEventType}) => {
  const [selectedOption, setSelectedOption] = React.useState(mapOptions[0].id);
  const [imageSrcData, setImageSrcData] = React.useState<string>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption((event.target as HTMLInputElement).value);
  };

  const {
    formState: {errors}
  } = useForm({
    criteriaMode: 'all'
  });

  return (
    <React.Fragment>
      <ErrorMessage
        errors={errors}
        name="multipleErrorInput"
        render={({messages}) => messages && Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)}
      />
      <TextInput required fullWidth label="Name" name="name" />
      <FormControl>
        <FormLabel id="label-map-radio-buttons-group-label">Select Map option</FormLabel>
        <RadioGroup aria-labelledby="label-radio-buttons-group-label" name="map-radio-buttons-group" value={selectedOption} onChange={handleChange}>
          {mapOptions.map((option) => (
            <FormControlLabel key={option.id} value={option.id} control={<Radio />} label={option.label} />
          ))}
        </RadioGroup>
      </FormControl>
      <ImageFileUpload onImageSrcChange={setImageSrcData} />

      {selectedOption === 'option1' && <GeoReferenceMap imageSrcData={imageSrcData} subEvent={subEvent} />}
      {selectedOption === 'option2' && <JpwMap subEvent={subEvent} imageSrcData={imageSrcData} />}
      {selectedOption === 'option3' && <KmlMap subEvent={subEvent} imageSrcData={imageSrcData} />}
    </React.Fragment>
  );
};

export default MapForm;
