import React from 'react';
// import UtmObj from '../../../services/UTM';

// import {angle, rotate, toVector, transform, vectorLen, orient} from '../../common/TransformVectors';
import {SubEventType} from 'components/SubEvent/SubEvents';
import {MapType} from 'components/Map/Maps';
import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useRecordContext} from 'components/record/useRecordContext';

// const toPoint = (pointUtm) => {
//   return {
//     lng: pointUtm.Easting,
//     lat: pointUtm.Northing
//   };
// };

// const convert = (track, map) => {
//   let utm = new UtmObj();
//   let polyline = [];
//   let pBottomLeft = toPoint(utm.convertLatLngToUtm(map.bottomLeft.lat, map.bottomLeft.lng, 10));
//   let pTopLeft = toPoint(utm.convertLatLngToUtm(map.topLeft.lat, map.topLeft.lng, 10));
//   let vec1 = toVector(pTopLeft, pBottomLeft);
//   let vec = {lng: 0, lat: -map.height};
//   let k = vectorLen(vec) / vectorLen(vec1);
//   let a = Math.acos(angle(vec1, vec));
//   if (orient({lng: 0, lat: 0}, vec1, vec) < 0) a = -a;

//   track.forEach((t) => {
//     let p = toPoint(utm.convertLatLngToUtm(t.lat, t.lng, 10));
//     let r = rotate(transform(toVector(pTopLeft, p), k), a);
//     polyline.push({
//       x: r.lng,
//       y: -r.lat
//     });
//   });
//   return polyline;
// };

const ExportTrack = ({subEvent}: {subEvent: SubEventType}) => {
  const [map, setMap] = React.useState<MapType>();
  const {record} = useRecordContext();
  console.log(record, subEvent);
  const [data, setData] = React.useState<string>();

  React.useEffect(() => {
    console.log(subEvent.maps);
    if (subEvent.maps?.length > 0) {
      setMap(subEvent.maps[0]);
    }
  }, []);

  const changeMap = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }
    const img = new window.Image();
    img.setAttribute('crossOrigin', '*');
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      // const polyline = convert(track, map);
      context.drawImage(img, 0, 0);
      context.beginPath();
      // context.moveTo(polyline[0].x, polyline[0].y);
      // polyline.forEach((p) => context.lineTo(p.x, p.y));
      context.lineWidth = img.width / 500;
      context.lineJoin = 'round';
      context.strokeStyle = 'rgba(255,0,255, 0.8)';
      context.stroke();
      setData(canvas.toDataURL());
    };
    img.src = process.env.REACT_APP_UPLOADS + (map?.url || '');
  };
  React.useEffect(changeMap, [map]);

  const handleChange = (event: SelectChangeEvent) => {
    const id = parseInt(event.target.value);
    setMap(subEvent.maps.find((map) => map.id === id));
  };

  return (
    <React.Fragment>
      <FormControl fullWidth>
        <Select id="map-select" value={map?.id + ''} label="Map" onChange={handleChange}>
          {subEvent.maps.map((map) => (
            <MenuItem key={map.id} value={map.id}>
              {map.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <img src={data} style={{width: '100%'}} alt="exported map" />
    </React.Fragment>
  );
};

export default ExportTrack;
