import React, {useState} from 'react';

import {SubEventType} from 'components/SubEvent/SubEvents';
import FilePickerDialog from 'components/forms/FilePickerDialog';
import {toast} from 'react-toastify';
import EditTrackForm from './EditTrackForm';
import {TrackPoint} from './SingleTrack';
import {Button} from '@mui/material';
import CreateDialogBody from 'components/forms/CreateDialogBody';
import {convertGpxToTrackArray} from 'services/gpxUtils';

const ImportTrackFromGPS = (props: ImportTrackFromGPSProps) => {
  const {
    subEvent,
    callback,
    competitor: {color}
  } = props;

  const [track, setTrack] = useState<TrackPoint[]>([]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>, callback: () => void) => {
    const reader = new window.FileReader();
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const extension = fileName.split('.').pop()?.toLowerCase();
      if (extension === 'gpx') {
        reader.onload = (e) => {
          setTrack(convertGpxToTrackArray(e.target?.result as string));
          callback();
        };
        reader.readAsText(file);
      } else {
        toast.error('Тhe file is not in correct format!');
      }
    }
  };

  return (
    <React.Fragment>
      <FilePickerDialog
        accept=".gpx"
        onFileChange={onFileChange}
        primaryButton={
          <Button type="submit" form="create_CompetitorTrack" variant="contained">
            Create
          </Button>
        }
      >
        <CreateDialogBody onSuccess={callback}>
          <EditTrackForm subEvent={subEvent} data={track} color={color} />
        </CreateDialogBody>
      </FilePickerDialog>
    </React.Fragment>
  );
};

export default ImportTrackFromGPS;

type ImportTrackFromGPSProps = {
  subEvent: SubEventType;
  competitor: {
    color: string;
    deviceName: string;
  };
  callback?: () => void;
};
