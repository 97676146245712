import React from 'react';
import {useResourceContext} from 'components/record/ResourceContext';
import {useRecordContext} from 'components/record/useRecordContext';
import ActionMenuItemConfirmDialog, {ActionMenuItemConfirmDialogProps} from './ActionMenuItemConfirmDialog';
import UpdateConfirmButton, {UpdateData} from 'components/forms/UpdateConfirmButton';

const ActionMenuItemUpdate = (props: ActionMenuItemUpdateProps) => {
  const {resource} = useResourceContext();
  const record = useRecordContext();

  const {
    label = 'Confirm',
    confirmTitle = `Update ${resource} item #${record.id}`,
    confirmContent = 'Are you sure you want to update this item?',
    onSuccess,
    data,
    ...rest
  } = props;

  return (
    <React.Fragment>
      <ActionMenuItemConfirmDialog
        {...rest}
        label={label}
        confirmContent={confirmContent}
        confirmTitle={confirmTitle}
        primaryButton={<UpdateConfirmButton onSuccess={onSuccess} data={data} />}
      />
    </React.Fragment>
  );
};

export default ActionMenuItemUpdate;

type ActionMenuItemUpdateProps = ActionMenuItemConfirmDialogProps & {
  data?: UpdateData;
};
