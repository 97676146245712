import React from 'react';
import {useController} from 'react-hook-form';
import {InputAdornment, TextField, TextFieldProps} from '@mui/material';
import {CompactPicker} from 'react-color';
import ColorButton from './ColorButton';

const ColorInput = (props: ColorInputProps) => {
  const {name, ...TextFieldProps} = props;

  const [showPicker, setShowPicker] = React.useState<boolean>(false);

  const {
    field: {value: valueColor, onChange: onChangeColor}
    // fieldState: {error: errorColor}
  } = useController({name});

  const colorButton = (
    <InputAdornment position="start">
      <ColorButton disablePopover={false} bgColor={valueColor} onClick={() => setShowPicker(true)} />
    </InputAdornment>
  );

  const onChange = (data: {hex: string}) => {
    onChangeColor(data.hex);
    setShowPicker(false);
  };

  return (
    <React.Fragment>
      <TextField
        onChange={(e) => {
          onChangeColor(e.target.value);
        }}
        value={valueColor}
        InputProps={{startAdornment: colorButton}}
        margin="normal"
        size="small"
        {...TextFieldProps}
      />
      {showPicker && (
        <div
          style={{
            position: 'absolute',
            zIndex: '2'
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px'
            }}
            onClick={() => setShowPicker(false)}
          />
          <CompactPicker color={valueColor} onChange={onChange} />
        </div>
      )}
    </React.Fragment>
  );
};

export default ColorInput;

type ColorInputProps = TextFieldProps & {
  name: string;
};
