import React, {ReactNode, useMemo} from 'react';
import {useDialogContext} from 'components/dialog/DialogContext';
import {RecordType} from 'components/record/RecordContext';
import {useResourceContext} from 'components/record/ResourceContext';
import {useRecordContext} from 'components/record/useRecordContext';
import {useFetch, useMutate} from 'hooks/useFetchData';
import {transformDatesAndOmitRelated} from 'services/utils';
import {SubmitHandler} from 'react-hook-form';
import {toast} from 'react-toastify';
import {FormContainer} from 'react-hook-form-mui';
import {CircularProgress} from '@mui/material';

const EditDialogBody = ({children, onSuccess}: {children: ReactNode; onSuccess?: () => void}) => {
  const {resource, params} = useResourceContext();
  const {close} = useDialogContext();
  const {record, id} = useRecordContext();

  const {data, loading} = useFetch<{item: RecordType}>('GET_ONE', resource, {variables: {...params, id}});

  const [mutate] = useMutate<{item: RecordType}>('UPDATE_ONE', resource, {variables: params});

  const defaults = useMemo(() => transformDatesAndOmitRelated(data?.item || record, resource), [data, record]);

  const handleEdit: SubmitHandler<React.FormEvent<HTMLFormElement>> = (payload) => {
    if (typeof mutate === 'function') {
      mutate({variables: {payload, id}})
        .then((res) => {
          if (res.data?.item) {
            const itemName = res.data?.item.name || res.data?.item.id ? ` ${res.data?.item.name || res.data?.item.id}` : '';
            toast.success(`${resource}${itemName} has been updated successfully!`);
            if (onSuccess && typeof onSuccess === 'function') {
              onSuccess();
            }
            close();
          }
        })
        .catch((error) => {
          if (error.message) {
            toast.error(error.message);
          }
        });
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <FormContainer FormProps={{id: `edit_${resource}`, noValidate: true}} defaultValues={defaults} onSuccess={handleEdit}>
      {children}
    </FormContainer>
  );
};

export default EditDialogBody;
