import {AutocompleteInput, TextInput} from 'components/forms/Inputs';
import {useFetch} from 'hooks/useFetchData';
import React from 'react';
type UserType = {id: number; firstName: string; lastName: string; userDevice: {id: number; name: string}};

const DeviceForm = () => {
  const {data, loading} = useFetch<{items: UserType[]}>('GET_LIST', 'User');
  const userOptions = React.useMemo(() => (data?.items || []).map((item) => ({id: item.id, label: `${item.firstName} ${item.lastName}`})), [data]);

  return (
    <React.Fragment>
      <TextInput required fullWidth label="Name" name="name" autoFocus />
      <TextInput required fullWidth label="Imei" name="imei" />
      <AutocompleteInput required fullWidth loading={loading} name="userId" label="User" options={userOptions} />
      <TextInput fullWidth label="Info" name="info" />
    </React.Fragment>
  );
};

export default DeviceForm;
