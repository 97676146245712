import React from 'react';

import MyEventsPage from '../components/MyEvents/MyEventsPage';
import EventPage from '../components/Event/EventPage';
import ImportSiDataPage from '../components/Competitor/ImportSiDataPage';

import SubEventPage from '../components/SubEvent/SubEventPage';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import {Outlet} from 'react-router';
import ResourceRecordContextProvider from 'components/record/ResourceRecordProvider';

export const MyEventsRoutes = {
  path: '/my_events',
  element: (
    <ResourceContextProvider resource="Event">
      <Outlet />
    </ResourceContextProvider>
  ),
  children: [
    {
      path: '/my_events/',
      element: <MyEventsPage />
    },
    {
      path: '/my_events/page/:page',
      element: <MyEventsPage />
    },
    {
      path: '/my_events/:slug',
      element: (
        <ResourceRecordContextProvider param="slug">
          <Outlet />
        </ResourceRecordContextProvider>
      ),
      children: [
        {
          path: '/my_events/:slug',
          element: <EventPage />
        },
        {
          path: '/my_events/:slug/:tab',
          element: <EventPage />
        },
        {
          path: '/my_events/:slug/si/import',
          element: <ImportSiDataPage />
        }
      ]
    }
  ]
};

export const MySubEventsRoutes = {
  path: '/sub_events/:id',
  element: (
    <ResourceContextProvider resource="SubEvent">
      <ResourceRecordContextProvider param="id">
        <Outlet />
      </ResourceRecordContextProvider>
    </ResourceContextProvider>
  ),
  children: [
    {
      path: '/sub_events/:id',
      element: <SubEventPage />
    },
    {
      path: '/sub_events/:id/:tab',
      element: <SubEventPage />
    }
  ]
};
