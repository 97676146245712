import React from 'react';
// import { useQuery } from '@apollo/client'

// import Loader from '../common/Loader'
// import CardFrame from '../common/CardFrame'
// import UserPreferredDevice from './Preferred/UserPreferredDevice'
// import { GET_USERS_AND_DEVICES } from '../../services/gqlQueries'

const UsersPage = () => {
  // const [users, setUsers] = useState([])
  // const [deviceList, setDeviceList] = useState([])

  // const onCompleted = (data) => {
  //   setDeviceList(data.getDevices.map(d => ({value: d.id, label: d.name})))
  //   setUsers(data.getUsers)
  // }

  // const {loading} = useQuery(GET_USERS_AND_DEVICES, { onCompleted: onCompleted })

  // if (loading) {
  //   return <Loader loading={loading} />
  // }

  // const onUpdate = (userId, preferred) => {
  //   setUsers(users => users.map(user => {
  //     if (user.id === userId) {
  //       user.preferredDevices = [preferred]
  //     }
  //     return user
  //   }))
  // }

  // const onDelete = (userId) => {
  //   setUsers(users => users.map(user => {
  //     if (user.id === userId) {
  //       user.preferredDevices = []
  //     }
  //     return user
  //   }))
  // }

  return (
    <div className="container-fluid row">
      {/* <div className='col-12 col-lg-12'>
      <CardFrame title="Users' preferred devices">
        <div className='users no-gutters'>
          {users.map(user =>
            <div key={user.id} className='row'>
              <div className='col-12 col-md-4'>{user.firstName} {user.lastName}</div>
              <div className='col-12 col-md-8'>
                <UserPreferredDevice 
                  deviceList={deviceList} 
                  userId={user.id}
                  onSave={onUpdate}
                  onDelete={onDelete}
                  callback={() => {}}
                  preferred={(user.preferredDevices && user.preferredDevices.length>0)?user.preferredDevices[0]:null}
                />
              </div>
            </div>)
          }
        </div>
      </CardFrame>
    </div> */}
    </div>
  );
};

export default UsersPage;
