import React from 'react';

import {useRecordContext} from 'components/record/useRecordContext';
import {Check, Place, Timer, TimerOff, Visibility, VisibilityOff} from '@mui/icons-material';
import {BooleanField, ListField} from 'components/list/Fields';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import ActionMenuItemUpdate from 'components/list/actionMenu/ActionMenuItemUpdate';
import {ActionMenuItemOrderDown, ActionMenuItemOrderUp} from 'components/list/actionMenu/ActionMenuItemsOrder';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';
import List from 'components/list/List';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import CreateButton from 'components/forms/CreateButton';
import ControlForm from './ControlForm';
import ActionMenuItemEdit from 'components/list/actionMenu/ActionMenuItemEdit';
import BulkDelete from 'components/forms/BulkDelete';
import BulkUpdate from 'components/forms/BulkUpdate';
import {Box} from '@mui/material';

const ControlsTable = () => {
  const {record: subEvent} = useRecordContext();

  return (
    <ResourceContextProvider resource="Control" params={{subEventId: subEvent.id}}>
      <List
        bulkOptions
        hidePagination
        title="Controls"
        avatar={<Place />}
        toolbar={
          <React.Fragment>
            <CreateButton label="Add New Control">
              <ControlForm subEvent={subEvent} />
            </CreateButton>
            <CreateButton label="Add Many Controls" many>
              <ControlForm subEvent={subEvent} multiControls />
            </CreateButton>
          </React.Fragment>
        }
        bulkActions={
          <Box gap={2} display="flex">
            <BulkUpdate data={{show: true}} label="Show" icon={<Visibility />} />
            <BulkUpdate data={{show: false}} label="Hide" icon={<VisibilityOff />} />
            <BulkUpdate data={{analytic: true}} label="Add analytic" icon={<Timer />} />
            <BulkUpdate data={{analytic: false}} label="Hide analytic" icon={<TimerOff />} />
            <BulkDelete />
          </Box>
        }
      >
        <ListField source="name" label="Name" />
        <ListField source="type" label="Type" />
        {subEvent.event.settings.useSI && <ListField source="siCode" label="SI" />}
        <BooleanField source="show" label="Show">
          <Check />
        </BooleanField>
        <BooleanField source="analytic" label="Analytic">
          <Check />
        </BooleanField>
        <ActionMenu label="Actions" align="right" width="5%">
          <ActionMenuItemEdit confirmContent={<ControlForm subEvent={subEvent} />} />
          <ActionMenuItemUpdate label="Show" icon={<Visibility />} data={{show: true}} />
          <ActionMenuItemUpdate label="Hide" icon={<VisibilityOff />} data={{show: false}} />
          <ActionMenuItemOrderUp />
          <ActionMenuItemOrderDown />
          <ActionMenuItemDelete />
        </ActionMenu>
      </List>
    </ResourceContextProvider>
  );
};

export default ControlsTable;
