import React, {useRef} from 'react';
import icons from '../Control/Icons/MarkerIcons';
import MapContainer from '../common/MapContainer';
import MapEventHandlers from '../common/MapEventHandlers';
import DraggableMarkers from '../common/DraggableMarker';
import {LatLngLiteral, LeafletEventHandlerFn, LeafletMouseEventHandlerFn, Map} from 'leaflet';
import {useController} from 'react-hook-form';

type InputLocationProps = {
  label: string;
  location?: LatLngLiteral;
  zoom?: number;
};

const InputLocation = (props: InputLocationProps) => {
  const {label} = props;

  const {
    field: {value: location, onChange: onChangeLocation},
    fieldState: {error: errorLocation}
  } = useController({name: 'location', defaultValue: {lat: 43, lng: 25}});

  const {
    field: {value: zoom, onChange: onChangeZoom},
    fieldState: {error: errorZoom}
  } = useController({name: 'zoom', defaultValue: 7});

  const mapRef = useRef<Map>(null);

  const handleClick: LeafletMouseEventHandlerFn = (e) => {
    if (!location) {
      const value = e.latlng;
      onChangeLocation(value);
      onChangeZoom(mapRef.current?.getZoom());
    }
  };

  const onZoomEvent: LeafletEventHandlerFn = () => {
    onChangeZoom(mapRef.current?.getZoom());
  };

  return (
    <div className="form-group has-feedback">
      {label && <label className="form-control-label">{label}</label>}
      <MapContainer
        ref={mapRef}
        center={location}
        zoom={zoom}
        className="leaflet-crosshair"
        style={{width: '100%', height: '500px', position: 'relative'}}
      >
        <MapEventHandlers click={handleClick} zoom={onZoomEvent} />
        <DraggableMarkers position={location} onUpdate={onChangeLocation} icon={icons['center']} />
      </MapContainer>
      {(errorLocation || errorZoom) && (
        <div className="invalid-feedback" style={{display: 'block'}}>
          {errorLocation?.message}
          {errorZoom?.message}
        </div>
      )}
    </div>
  );
};

export default InputLocation;
