import React from 'react';
import {Link} from 'react-router-dom';

import {useRecordContext} from 'components/record/useRecordContext';
import {Add, Check, Map} from '@mui/icons-material';
import {Button} from '@mui/material';
import {BooleanField, LinkField, ListField} from 'components/list/Fields';
import List from 'components/list/List';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import ActionMenuItemEdit from 'components/list/actionMenu/ActionMenuItemEdit';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';
import CreateButton from 'components/forms/CreateButton';
import SubEventForm from './SubEventForm';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import {LatLngLiteral} from 'leaflet';
import {RouteType} from 'components/Map/Routes';
import {MapType} from 'components/Map/Maps';
import ImportSubEventsButton from './ImportSubEventsButton';

export type EventType = {
  location: LatLngLiteral;
  zoom: number;
  settings: {
    useSI: boolean;
  };
  startDate?: string;
  endDate?: string;
  slug: string;
  status?: string;
};

export type SubEventType = {
  id: number;
  name: string;
  sub: string;
  useGPS: false;
  event: EventType;
  routes: RouteType[];
  maps: MapType[];
};

const SubEvents = () => {
  const {record: event} = useRecordContext();

  return (
    <ResourceContextProvider resource="SubEvent" params={{slug: event.slug}}>
      <List
        bulkOptions={false}
        hidePagination
        title="SubEvents"
        avatar={<Map />}
        toolbar={
          <React.Fragment>
            <CreateButton size="small">
              <SubEventForm />
            </CreateButton>
            <ImportSubEventsButton />
            <Button component={Link} variant="contained" size="small" to={`/my_events/${event.slug}/subevents/import`}>
              <Add /> Import XML (IOF v3)
            </Button>
          </React.Fragment>
        }
      >
        <LinkField source="name" label="Sub Event" link={(subEvent) => `/sub_events/${subEvent?.id}`} />
        <ListField source="sub" label="Abbreviation" />
        <BooleanField source="useGPS" label="Use GPS">
          <Check />
        </BooleanField>
        <ActionMenu label="Actions" align="right" width="5%">
          <ActionMenuItemEdit confirmContent={<SubEventForm />} />
          <ActionMenuItemDelete />
        </ActionMenu>
      </List>
    </ResourceContextProvider>
  );
};

export default SubEvents;
