import React from 'react';
import {SelectInput, TextInput, SwitchInput} from 'components/forms/Inputs';
import ControlPositionInput from './ControlPositionInput';
import {SubEventType} from 'components/SubEvent/SubEvents';

const TYPES = [
  {label: 'Default', id: 'default'},
  {label: 'Circle', id: 'circle'},
  {label: 'Triangle', id: 'triangle'},
  {label: 'Square', id: 'square'}
];

const ControlForm = (props: ControlFormProps) => {
  const {subEvent, multiControls = false} = props;

  return (
    <React.Fragment>
      {!multiControls && <TextInput required fullWidth label="Name" name="name" autoFocus />}
      <SelectInput fullWidth required name="type" label="Type" options={TYPES} />
      <SwitchInput sx={{width: '100%'}} label="Show" name="show" />
      <SwitchInput sx={{width: '100%'}} label="Analytic" name="analytic" />
      {subEvent.event.settings.useSI && <TextInput fullWidth label="Si Code" name="siCode" type="number" />}
      <ControlPositionInput subEvent={subEvent} multi={multiControls} />
    </React.Fragment>
  );
};

export default ControlForm;

type ControlFormProps = {
  subEvent: SubEventType;
  multiControls?: boolean;
};
