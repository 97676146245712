import React from 'react';

import {NearMe} from '@mui/icons-material';
import List from 'components/list/List';
import {DateField, ListField} from 'components/list/Fields';
import {Stack} from '@mui/material';
import CreateButton from 'components/forms/CreateButton';
import DeviceForm from './DeviceForm';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import ActionMenuItemEdit from 'components/list/actionMenu/ActionMenuItemEdit';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';

export type DeviceType = {
  id: number;
  name: string;
};

const DevicesPage = () => {
  return (
    <React.Fragment>
      <List
        avatar={<NearMe />}
        title="Devices"
        bulkOptions={true}
        hidePagination
        refresh
        toolbar={
          <Stack direction="row" alignItems="right" justifyContent="flex-end" gap={2}>
            <CreateButton label="Create Device">
              <DeviceForm />
            </CreateButton>
          </Stack>
        }
      >
        <ListField source="name" label="Name" />
        <ListField source="battery" label="Battery" />
        <DateField source="last" label="Last check" />
        <ListField source="imei" label="imei" />
        <ActionMenu label="Actions" align="right" width="5%">
          <ActionMenuItemEdit confirmContent={<DeviceForm />} />
          <ActionMenuItemDelete />
        </ActionMenu>
      </List>
    </React.Fragment>
  );
};

export default DevicesPage;
