import React from 'react';

import {useRecordContext} from 'components/record/useRecordContext';
import List from 'components/list/List';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import {ListField} from 'components/list/Fields';
import {CheckCircleOutline, Draw, RemoveCircleOutline} from '@mui/icons-material';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';
import ActionMenuItemUpdate from 'components/list/actionMenu/ActionMenuItemUpdate';
import EntriesFilters from './EntriesFilters';
import ButtonDialog from 'components/forms/ButtonDialog';
import EntriesEmail from './Email/EntriesEmail';

const Entries = () => {
  const {record: event} = useRecordContext();

  return (
    <ResourceContextProvider resource="Entry" params={{slug: event.slug}}>
      <List
        bulkOptions={false}
        hidePagination
        title="Entries"
        listFilters={<EntriesFilters />}
        avatar={<Draw />}
        refresh
        toolbar={
          <React.Fragment>
            <ButtonDialog buttonLabel="Import e-mails" dialogTitle="Emails">
              <EntriesEmail event={event} />
            </ButtonDialog>
          </React.Fragment>
        }
      >
        <ListField source="userName" label="User Name" />
        {/* <DateField source="createdAt" label="Date" /> TODO: */}
        <ListField source="subEvent.name" label="Sub Event" />
        <ListField source="status" label="Status" />
        <ActionMenu label="Actions" align="right" width="5%">
          <ActionMenuItemUpdate label="Accept" icon={<CheckCircleOutline />} data={{status: 'accept'}} />
          <ActionMenuItemUpdate label="Reject" icon={<RemoveCircleOutline />} data={{status: 'reject'}} />
          <ActionMenuItemDelete />
        </ActionMenu>
      </List>
    </ResourceContextProvider>
  );
};

export default Entries;
