import React from 'react';
import ButtonDialog from './ButtonDialog';
import {useResourceContext} from 'components/record/ResourceContext';
import {useListContext} from 'components/list/ListContext';
import UpdateConfirmButton, {UpdateData} from './UpdateConfirmButton';

const BulkUpdate = (props: BulkUpdateProps) => {
  const {resource} = useResourceContext();
  const {refetch, setSelected} = useListContext();
  const {label = 'Update', confirmTitle = `Update ${resource} items`, data, icon, ...rest} = props;

  const onSuccess = () => {
    setSelected([]);
    refetch();
  };

  return (
    <ButtonDialog
      buttonLabel={label}
      variant="text"
      maxWidth="sm"
      startIcon={icon}
      dialogTitle={confirmTitle}
      primaryButton={<UpdateConfirmButton onSuccess={onSuccess} many data={data} />}
      {...rest}
    >
      Are you sure you want to update this items?
    </ButtonDialog>
  );
};

type BulkUpdateProps = {
  data?: UpdateData;
  icon?: React.ReactNode;
  label?: string;
  confirmTitle?: string;
  confirmContent?: React.ReactNode;
};

export default BulkUpdate;
